
// import isCont from '@/services/member/isCont';

export const homeRoute = [
  // Homepage
  {
    path: '/',
    name: 'HomeView',
    component: () => import('@/views/HomeView.vue'),
    meta: { 
      title: 'งานทั้งหมด'//() => isCont() ? 'งานของฉัน' : 'งานทั้งหมด'
    }
  },
  { 
    path: "/:pathMatch(.*)*", 
    name: 'PageNotFoundView',
    component: () => import('@/views/PageNotFoundView.vue'),
    meta: { 
      title: 'ไม่พบหน้านี้'
    }
  }
];