/**
 * Get member authen from API
 *  if member status is not disable or removed
 *  this function will get new token for update in state
 */
import store from '@/store';
import { loading } from '@/utilities/display';
import { http } from "@/utilities/http";

export default async function getAuth() {
  let authStatus = false;
  const token = store.getters.token;
  
  await http
        .post('/member/auth/', {token})
        .then( (res) => {
          store.commit('setAuthToken', res.data.token);
          authStatus = true;
        })
        .catch(() => {
          // display loading before redirect to 'login'
          loading(); 
        }); 
  
  return authStatus;
}