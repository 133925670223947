<script setup>
import ProposalTr   from '../../form/table/ProposalTr.vue';
import AddonBoqTr from '@/components/job/form/table/AddonBoqTr.vue'
import ButtonSet    from '@/components/display/form/ButtonSet.vue';
import InputToggle  from '@/components/display/form/InputToggle.vue';
import WarningCard  from '@/components/job/module/JobWarningCard.vue'
import NotiBox from '@/components/display/noti/NotiBox.vue'

import { ref, reactive, onMounted, defineProps, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import currentUser from '@/services/member/currentUser';
import { loading } from '@/utilities/display';
import { http } from '@/utilities/http';
import { toFloat, priceFormat } from '@/utilities/number';
import getMeasureUnit from '@/services/setting/getMeasureUnit'



const router = useRouter();
const props = defineProps({ data: Object });
const loaded = ref(false);
const memberId = currentUser('member_id');
const myData = ref([]);
const unit = ref([]);
const state = reactive({
  boq: {
    child: []
  },
  boq_extra: {
    child: []
  },
  total: 0,
  discount: 0,
  after_discount: 0,
  vat: 0,
  vat_inc: 0,
  net_amount: 0,
  remark: '',
  proposal_status: 0,
});




/** Lift Cycle ----------- */
onMounted(async () => {
  unit.value = await getMeasureUnit();
  
  if(props.data.survey_boq.length > 0) {
    // find my proposal
    const myProposal = props.data.cont.find(item => item.member_id === memberId);
    myData.value = myProposal;

    if(myProposal.boq.length > 0) {
      state.boq.child = prepareChild(myProposal.boq);
      state.boq_extra.child = prepareChild(myProposal.boq_extra);
      state.total = myProposal.total
      state.discount = myProposal.discount
      state.after_discount = myProposal.after_discount
      state.vat = myProposal.vat
      state.vat_inc = myProposal.vat_inc;// alert(myProposal.vat_inc);
      state.net_amount = myProposal.net_amount;
      state.remark = myProposal.remark;
      state.proposal_status = myProposal.proposal_status;
    } else {
      state.boq.child = prepareChild(props.data.survey_boq);
    }
  }



  loaded.value = true;
})

watchEffect(() => {
  if(state.boq === null) return false;
  
  state.total = 0;
  state.boq.child.forEach(item => {
    state.total += parseFloat(item.total);
  });
  state.boq_extra.child.forEach(item => {
    state.total += parseFloat(item.total);
  });

  state.total           = toFloat(state.total)
  state.after_discount  = parseFloat(state.total) - parseFloat(state.discount);
  state.vat             = state.vat_inc == 1 
                            ? parseFloat(toFloat(state.after_discount * 0.07))
                            : state.vat = 0;
  state.net_amount      = toFloat(state.after_discount + state.vat);
})


/** Method ----------- */
const prepareChild = (data) => {
  data.forEach((item, index)=> {
    if(item.price_type === undefined) {
      item.price_type = 1;
      item.mat_price  = 0;
      item.man_cost   = 0;
      item.net_price  = 0;
      item.total      = 0;
      item.spec       = '';
    }
    if (item.child === undefined) {
      item.child = [];
    } else if (item.child.length > 0) {
      item.child = prepareChild(item.child);
    }

    data[index] = item;
  })
  console.log(data);
  return data;
}

const addBoqExtra = () => {
  state.boq_extra.child.push({
    title: '',
    detail: '',
    value: '',
    unit: '',
    parent: '',
    price_type: 1,
    mat_price: 0,
    man_cost: 0,
    net_price: 0,
    total: 0,
    spec: '',
    child: [],
  });
}


// on submit form
const onSubmit = async () => {
  loading();

  //// prepare data
  let data = {...state};
  data.boq = data.boq.child;
  data.boq_extra = data.boq_extra.child;
  if(data.vat_inc != 1) {
    data.vat_inc = 0;
  }
  data.proposal_status = 9;

  //// save data
  await http
        .patch(`/job/${props.data.job_id}/interest/${memberId}`, data)
        .then(() => router.go(0))
        .catch(() => alert('การบันทึกข้อมูลผิดพลาด !!!'))
  loading(false);
}
</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step4"
  >
    <WarningCard v-if="data.survey_boq.length == 0" />
    <WarningCard 
      v-else-if="myData.show_status == 0" 
      title="การเสนอราคาของท่านถูกปิด"
      icon="close"
    />
    <div v-else>
      <NotiBox
        v-if="state.proposal_status == 9"
        title="คุณได้เสนอราคางานนี้แล้ว"
        text="กรุณารอลูกค้ารอลูกค้าตรวจสอบราคาของท่าน หากสงสัยสามารถติดต่อเจ้าหน้าที่เพิ่มเติมได้"
        icon="clock-blue"
      />
      <NotiBox
        title="หมายเหตุ"
        text="ผู้รับเหมาจำเป็นต้องกรอกค่าแรงและค่าวัสดุหรือราคาเหมาด้วยตัวเลขและสามารถใช้จุดทศนิยมได้เพียง 2 ตำแหน่งเท่านั้น"
        icon="alert-orange"
      />
      <!-- <pre>
        {{ state.boq }}
      </pre> -->

      <form 
        @submit.prevent="onSubmit"
        class="form j-tb__boq"
      >

        <fieldset class="tb__wrap">
          
          <table class="tb">
            <colgroup>
              <col style="width: 50px" />
              <col />
              <col style="width: 180px" />
              <col />
              <col style="width: 100px" />
              <col style="width: 100px" />
              <col />
              <col style="width: 100px" />
              <col style="width: 130px" />
              <!-- <col style="width: 200px" /> -->
            </colgroup>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>หัวข้อ/รายละเอียด</th>
                <th>ปริมาณงาน</th>
                <th class="td-check">&nbsp;</th>
                <th class="td-price">ค่าวัสดุ</th>
                <th class="td-price">ค่าแรง</th>
                <th class="td-check">&nbsp;</th>
                <th>ราคาเหมา</th>
                <th class="col--right">มูลค่ารวม (บาท)</th>
                <th>หมายเหตุ (สเปค)</th>
              </tr>
            </thead>

            
            <tbody class="boq__group"> 
              <tr>
                <th class="boq__group__title" colspan="10">
                  <strong>รายการจาก BOQ หลัก</strong>
                </th>
              </tr>
              <ProposalTr
                :data="state.boq"
                :index="-1"
                parent="-1"
                :editable="data.winner == 0"
              />

              <tr>
                <th class="boq__group__title" colspan="10">
                  <strong>รายการเสนอเพิ่มของผู้รับเหมา</strong>
                  <span v-if="state.boq_extra.child.length == 0">
                    -
                    <a 
                      href="#" 
                      class="add"
                      @click.prevent="addBoqExtra()"
                    >เพิ่มรายการ</a>
                  </span>
                </th>
              </tr>
              <AddonBoqTr
                :data="state.boq_extra"
                :index="-1"
                :unit="unit"
                parent="-1"

                :editable="data.winner == 0"
              />
            </tbody>


            <tfoot>
              <tr class="foot">
                <th scope="row" colspan="8">รวม</th>
                <td class="col--right">
                  {{ priceFormat(state.total) }}
                </td>
                <th>&nbsp;</th>
              </tr>
              <tr class="foot">
                <th scope="row" colspan="8">ส่วนลด</th>
                <td class="col--right">
                  <input 
                    v-model="state.discount"
                    type="number"
                    min="0"
                    step="0.01"
                    required
                    :disabled="data.winner !== 0"
                  />
                </td>
                <th>&nbsp;</th>
              </tr>
              <tr class="foot">
                <th scope="row" colspan="8">คงเหลือ</th>
                <td class="col--right">
                  {{ priceFormat(state.after_discount) }}
                </td>
                <th>&nbsp;</th>
              </tr>
              <tr class="foot">
                <th scope="row" colspan="8">
                  <InputToggle
                    v-model="state.vat_inc"
                    :disabled="data.winner !== 0"
                    style="margin-right: 5px"
                  />
                  ภาษีมูลค่าเพิ่ม 7%
                </th>
                <td class="col--right">{{ priceFormat(state.vat) }}</td>
                <th>&nbsp;</th>
              </tr>
              <tr class="foot-net">
                <th scope="row" colspan="8">มูลค่างานสุทธิ</th>
                <td class="col--right">
                  {{ priceFormat(state.net_amount) }}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr class="foot footer-remark">
                <th scope="row" colspan="8">
                  หมายเหตุ
                </th>
                <td class="col--right" colspan="2">
                  <textarea
                    v-model="state.remark"
                    class="proposal--remark"
                  />
                </td>
              </tr>
            </tfoot>
          </table>

          
        </fieldset>
        <div class="mt--2">
          <ButtonSet></ButtonSet>
        </div>
      </form>
    </div>
    <!-- end: BOQ -->
  </div>
</template>


<style scoped>

.td-price-split input[type=number],
.td-price-net input[type=number] {
  margin-left: 5px;
  width: 75px !important;
}
.proposal--remark {
  width: 100%;
}
.footer-remark th {
  vertical-align: top;
}
</style>