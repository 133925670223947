<script setup>

import { ref, onMounted } from 'vue';
import { http } from '@/utilities/http';
import { loading } from '@/utilities/display';
import { useRoute, useRouter } from 'vue-router';
import progressIdToTitle from '@/services/job/progress/progressIdToTitle'
import progressIdToSlug from '@/services/job/progress/progressIdToSlug'
const moment = require('moment');
moment.locale("th");


const route = useRoute();
const router = useRouter();
const loaded = ref(false);


/** Lift Cycle ----------- */
onMounted(() => {
  loaded.value = true;
})


/** Method ----------- */
const updateProgress = async (type) => {
  const job_id = route.params.id;

  loading()
  await http
        .post('/job/'+job_id+'/timeline', {type: type})
        .then(res => {
          router.push(`/job/${job_id}/timeline/${res.data.insert_id}/` + 
                      progressIdToSlug(type))
        })
        .catch(() => {
          alert('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง !!!')
          loading(false)
        })
}
</script>

<template>
  <div 
    v-if=" loaded "
    class="progress-button"
  >
    <a 
      @click.prevent="updateProgress(1)"
      href="#"
      class="pb-col"
    >
      <img src="@/assets/images/progress/checkin.svg" />
      <span>
        {{progressIdToTitle(1)}}
      </span>
    </a>
    <a 
      @click.prevent="updateProgress(2)"
      href="#"
      class="pb-col"
    >
      <img src="@/assets/images/progress/checkout.svg" />
      <span>
        {{progressIdToTitle(2)}}
      </span>
    </a>
    <a 
      @click.prevent="updateProgress(5)"
      href="#"
      class="pb-col"
    >
      <img src="@/assets/images/progress/update.svg" />
      <span>
        {{progressIdToTitle(5)}}
      </span>
    </a>
    <a 
      @click.prevent="updateProgress(10)"
      href="#"
      class="pb-col"
    >
      <img src="@/assets/images/progress/appointment.svg" />
      <span>
        {{progressIdToTitle(10)}}
      </span>
    </a>
  </div>
</template>

<style scoped>
.progress-button {
  display: flex;
  margin-bottom: 20px;
}
.progress-button .pb-col {
  margin-right: 10px;
  padding: 15px 10px 10px 10px;
  border: solid 1px #e9e9e9;
  border-radius: 8px;
  text-align: center;
  width: 25%;
  font-weight: bold;
  color: #000;
}
.progress-button .pb-col img {
  display: block;
  float: none;
  margin: 0 auto 5px auto;
  height: 36px;
}
</style>