<script setup>
import { reactive, onMounted, defineProps, watch } from 'vue'; //ref, 
import { hasError } from '@/utilities/validate';


const props = defineProps({
  // data from v-model
  modelValue: [String, Number, Array],
  referId: {
    type: String,
    default: ''
  },

  // this input label
  label: {
    type: String,
    default: '&nbsp;'
  },

  // input type
  inputType: {
    type: String,
    default: 'text'
  },

  // placeholer
  placeholder: {
    type: String,
    default: ''
  },
  
  // validate object
  validate: {
    type: Object
  },
  required: {
    type: Boolean,
    default: false
  },

  // rows for textarea only
  rows: {
    type: Number,
    default: 3
  },

  // for dropdown only
  hidePlaceHolder: {
    type: Boolean,
    default: false
  },
  defaultOption: {
    type: [String, Number],
    default: ''
  },
  groupOption: {
    type: Boolean,
    default: false
  },
  option: Object,
  
  // description
  text: {
    type: String,
    default: ''
  },

  // event
  onBlur: Function
})
const state = reactive({
  data: []
});


/** Life Cycle ------------------- */
onMounted(() => {
  if (props.inputType === 'checklist' || props.inputType === 'radiolist') { //
    state.data = props.modelValue;
  }
})

watch(() => props.referId , (n,o) => {
  if(n !== o) {
    state.data = [];
  }
})

/** Method ------------------- */
// detect validate error if has props.validate
const wrapperClass = () => {
  return props.validate !== undefined ? hasError(props.validate) : {};
}
</script>

<template>
  <div 
    class="input__round"
    :class="wrapperClass()"
  >
    <label class="label">
      {{ label }}
      <span 
        v-if="required"
        class="required"
      >*</span>
    </label>
    
    <textarea
      v-if=" inputType === 'textarea' "
      :value=" modelValue "
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
      :rows=" rows "
      :required="required"
    />

    <select
      v-else-if=" inputType === 'select' && !groupOption "
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      placeholder=""
      :required="required"
    >
      <option 
        v-if=" !hidePlaceHolder "
        :value="defaultOption"
      >
        {{ placeholder }}
      </option>
      <option 
        v-for="o in option"
        :key="o.id"
        :value="o.id"
      >{{ o.name }}</option>
    </select>

    <select
      v-else-if=" inputType === 'select' && groupOption "
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      placeholder=""
      :required="required"
    >
      <option 
        v-if=" !hidePlaceHolder "
        :value="defaultOption"
      >
        {{ placeholder }}
      </option>
      <optgroup
        v-for="og in option"
        :key="og.id"
        :label="og.name"
      >
        <option 
          v-for="o in og.child"
          :key="o.id"
          :value="o.id"
        >{{ o.name }}</option>
      </optgroup>
    </select>

    <div 
      v-else-if=" inputType === 'checklist' || inputType === 'radiolist' "
      class="checklist"
    >
      <span 
        v-for="o in option"
        :key="o.id"
        class="checklist-item"
      >
        <!-- class="col col-2 p--0" -->
        <input 
          v-if="inputType === 'checklist'"
          v-model="state.data"
          :value="o.id"
          type="checkbox"
          @change="$emit('update:modelValue', JSON.parse(JSON.stringify(state.data)))"
        >
        <input 
          v-else
          v-model="state.data"
          :value="o.id"
          type="radio"
          @change="$emit('update:modelValue', state.data)"
        >
        {{ o.name }}
      </span>
    </div>

    <input 
      v-else
      :value="modelValue"
      :type="inputType"
      :placeholder="placeholder"
      :required="required"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="$emit('blur')"
    />
  </div>
</template>


<style scoped>
.checklist {
  margin: 5px 0 3px 0;
}
.checklist-item {
  display: inline-block;
  padding-right: 20px;
}
</style>