<script setup>
/**
 * Button set for general form
 *    This contain 'submit' and 'cancel' button
 *    - submit active with onSubmit method from parent component
 *    - cancel will refresh current page by 'onCancel' methond
 * 
 * @param {submitTitle} - text in title button
 * @param {cancelTitle} - text in cancel button
 * 
 */

import Button from '@/components/display/form/ButtonRound.vue';
import { defineProps } from 'vue';
import { useRouter }   from 'vue-router';


/// initial variables
const router = useRouter();
const props = defineProps({
  submitTitle: {
    type: String,
    default: 'บันทึกข้อมูล'
  },
  submitIcon: {
    type: String,
    default: 'save'
  },
  cancelTitle: {
    type: String,
    default: 'ยกเลิก'
  },
  cancelIcon: {
    type: String,
    default: 'cancel'
  },
  cancelMethod: {
    type: Function
  },
});

/// on cancel method
const onCancel = () => {
  // const conf = confirm('คุณต้องการยกเลิกข้อมูลที่กรอกไว้ใช่หรือไม่ ?');
  // if(conf) {
  if (props.cancelMethod !== undefined) {
    props.cancelMethod();
  } else {
    router.go();
  }
  // }
}
</script>


<template>
  <div class="form__button row">
    
    <div class="col col--2">
      <Button 
        @click="onCancel"
        :text="props.cancelTitle" 
        :icon="props.cancelIcon"
        type="button"
      />
    </div>


    <div class="col col--2">
      <Button 
        :text="props.submitTitle"
        :icon="props.submitIcon"
        class="btn--submit"
      />
    </div>
  </div>
</template>