<script setup>
import ProposalTr from './ProposalTr.vue';
import { ref, onMounted, computed, defineProps, watchEffect } from 'vue';
// import { toFloat } from '@/utilities/number';
const numeral = require('numeral');

const props = defineProps({
  index: Number,
  parent: String,
  data: Object,
  unit: Object,
  editable: {
    type: Boolean,
    default: true
  }
});
const item = ref([]);
const childParent = ref('');
const hasChild = computed(() => {
  // return false;
  if(item.value.child=== undefined) {
    return true
  } else {
    return item.value.child.length > 0
  }
});


/** Life Cycle -----------------------*/
onMounted(() => {
  item.value = props.data;

  if (props.parent === '-1') {
    childParent.value = '';
  } else if(props.parent !== '') {
    childParent.value = props.parent + '.';
    item.value.parent = childParent.value;
  }
})

watchEffect(() => {
  if(item.value.child === undefined) return 0;

  if(item.value.child.length > 0) {
    item.value.total = 0;
    item.value.child.forEach((c_item)=> {
      item.value.total += Math.round(c_item.total * 100) / 100;
    })
    return;
  }
  // state.total = toFloat(state.total)

  let mat_price = parseFloat(item.value.mat_price);
  let man_cost = parseFloat(item.value.man_cost);
  let cost_flat = parseFloat(item.value.net_price);

  if(item.value.price_type == 1) {
    item.value.total = (isNaN(mat_price) || isNaN(man_cost)) 
                        ? 0
                        : (mat_price + man_cost) * item.value.value;
  } else {
    item.value.total = isNaN(cost_flat) ? 0 : cost_flat;
  }
  // item.value.total = parseFloat(toFloat(item.value.total));
  item.value.total = Math.round(item.value.total * 100) / 100;
});





/** Method -----------------------*/
/**
 * Generate Number Indent class
 */
 const numberIndentClass = () => {
  if(item.value.parent === undefined) return '';
  
  return 'pl--' + (item.value.parent.split(".").length - 1 )
}
</script>

<template>
  <tr v-if=" index !== -1">
    <td>
      <div :class="numberIndentClass()">
        {{ childParent + (index+1) }}</div>
    </td>
    <td>
      {{ item.name }}

      <div 
        v-if="item.detail !== ''"
        class="description"
      >
        {{item.detail}}
      </div>
    </td>
    <td>
      <span v-if="!hasChild">
        {{ item.value }}
        {{ item.unit }}
        <!-- {{item.price_type}} -->
      </span>
    </td>

    <td class="td-check">
      <input 
        v-if="!hasChild"
        type="radio"
        v-model="item.price_type"
        value="1"
        :disabled=" !editable "
      />
    </td>


    <!-- 
      Noted-001
      ค่าแรงกับค่าวัสดุสลับค่าตัวแปลการอยู่ จะปรับแก้ตอนระบบ BOQ ที่เสนอใหม่เสร็จ 
    -->
    <td class="td-price">
      <input 
        v-if="!hasChild"
        type="number"
        v-model="item.man_cost"
        min="0"
        step="0.01"
        :required=" item.price_type==9 "
        :disabled=" !editable || item.price_type==9 "
      />
    </td>
    <td class="td-price">
      <input 
        v-if="!hasChild"
        type="number"
        v-model="item.mat_price"
        min="0"
        step="0.01"
        :required=" item.price_type==1 "
        :disabled=" !editable || item.price_type==9 "
      />
    </td>
    <!-- end: Noted-001 -->

    <td class="td-check">
      <input 
        v-if="!hasChild"
        type="radio"
        v-model="item.price_type"
        value="9"
        :disabled=" !editable "
      />
    </td>
    <td class="td-price">
      <input 
        v-if="!hasChild"
        type="number"
        v-model="item.net_price"
        min="0"
        step="0.01"
        :required=" item.price_type==9 "
        :disabled=" !editable || item.price_type==1 "
      />
    </td>
    <td class="col--right">
      <span v-if="!hasChild">
        {{ numeral(item.total).format('0,0.00')}}
      </span>
    </td>
    <td class="td-price">
      <input 
        v-if="!hasChild"
        type="text"
        v-model="item.spec"
        :disabled=" !editable "
      />
    </td>
  </tr>

  <ProposalTr
    v-for="(child, childIndex) in item.child"
    :key="child"
    :data="child"
    :index="childIndex"
    :parent="parent === '-1' ? '' : childParent + (index+1)"
    :editable="editable"
  />
</template>