<script setup>
import Title          from '@/components/display/text/SectionTitle.vue';
// import Box            from '@/components/container/SectionBox.vue';
import ContractorInfo from '@/components/job/module/ContractorBoxInfo.vue'
import ReviewItem     from '@/components/job/detail/review/JobReviewItemComp.vue'
import Input          from '@/components/display/form/InputRound.vue';
import ButtonSet      from '@/components/display/form/ButtonSetFullWidth.vue';
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import InfoRow from '@/components/display/info/InfoRow.vue'


import { ref, onMounted, defineProps, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { http } from '@/utilities/http';
import currentUser from '@/services/member/currentUser';
import { loading } from '@/utilities/display';



const router = useRouter();
const props = defineProps({ data: Object });
const loaded = ref(false);
const isReviewStep = ref(false);
const totalScore = ref(0);
const memberRole = currentUser('role');
const state = reactive({
  review: {
    score: {
      key_1: {
        title: 'การให้บริการ',
        score: 0,
      },
      key_2: {
        title: 'ฝีมือและความเชี่ยวชาญ',
        score: 0,
      },
      key_3: {
        title: 'ความคุ้มค่าของราคา',
        score: 0,
      },
    },
    comment: ''
  }
});



/** Life Cycle ----------- */
onMounted(() => {
  // detect review step
  let noneWithdrawBill = props.data.agreement[0].bill.filter(item => item.withdraw_status != 9).length
  if(noneWithdrawBill === 0) {
    isReviewStep.value = true;
  }
  // ifisReviewStep

  // detect review data
  if(props.data.review.score !== undefined) {
    Object.values(props.data.review.score).forEach(item => {
      totalScore.value += item.score;
    })
    totalScore.value = parseFloat(totalScore.value / 3).toFixed(1);
  }

  loaded.value = true;
  loading(false)
})





/** Method ----------- */
const onSubmit = async () => {
  loading()
  await http
        .patch('/job/' + props.data.job_id, state)
        .then(res => {
          console.log(res.data)
          console.log(router)
          router.go(0);
        })
}
</script>

<template>
  <ContractorInfo 
    v-if="loaded"
    :info="data.winner_meta" 
    class="had-slot"
  >

    <WarningCard v-if=" !isReviewStep " />

    <div 
      v-else-if=" memberRole==='customer' "
      class="job__review"
    >
      <!-- if role = custmer and data haven't review score -->
      <form
        v-if=" data.review.length === 0 "
        @submit.prevent="onSubmit"
      >
        <Title title="ให้คะแนนรีวิวผู้รับเหมา"></Title>
        <fieldset>
          <ReviewItem  
            v-for="item in state.review.score"
            :key="item"
            :data="item"
          />
          <Input
            v-model="state.review.comment"
            label="ความคิดเห็น"
            inputType="textarea"
          />
          <ButtonSet text="แสดงความคิดเห็น" />
        </fieldset>
      </form>

      <!-- else data had review score -->
      <div v-else>
        <div class="score-title">คะแนนรีวิว</div>
        <div class="score-result">{{totalScore}} / 5.0</div>
        <ReviewItem  
          v-for="item in data.review.score"
          :key="item"
          :data="item"
          :editable="false"
        />
        <InfoRow 
          title="ความคิดเห็น"
          class="mt--2"
        >
          {{data.review.comment}}
        </InfoRow>
      </div>
    </div>

    <WarningCard
      v-else
      title="งานของท่านดำเนินการเสร็จแล้ว"
      icon="check-green"
    />
  </ContractorInfo>

</template>

<style scoped>
.review__content ol {
  padding-left: 25px;
}
.review__content li {
  list-style-type: decimal;
}
</style>