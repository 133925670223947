
export const publicRoutePath = [
  '/login',
  '/line-login',
  '/register/customer',
  '/register/contractor',
  '/forget-password',
  '/reset-password',
  '/term-of-use',
];

export const publicRouteName = [
  'RegisterTermView',
  'MemberVerifyEmailView',
  'PreviewContractor',
];