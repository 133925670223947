export const contractor = [
  {
    name: 'บริษัท ณรงฤทธิ์ แอสเซ็ท จำกัด',
    avatar: '/avatar/cont/1.jpg',
    finish: 3,
  },
  {
    name: 'ทีมอ๊อฟ เซอร์วิส',
    avatar: '/avatar/cont/2.jpg',
    finish: 5,
  },
  {
    name: 'ห้างหุ้นส่วนจำกัด แสงทองคำ',
    avatar: '/avatar/cont/3.jpg',
    finish: 2,
  },
  {
    name: 'บริษัท ยศสา จำกัด',
    avatar: '/avatar/cont/4.jpg',
    finish: 1,
  },
  {
    name: 'บริษัท ฟาสท์คอนส์ จำกัด',
    avatar: '/avatar/cont/5.jpg',
    finish: 6,
  },
  {
    name: 'บริษัท กรีน พลัส คอนสตรัคชั่น แอนด์ เทคโนโลยี จำกัด',
    avatar: '/avatar/cont/6.jpg',
    finish: 1,
  },
  {
    name: 'บริษัท ลุคกู๊ด จำกัด',
    avatar: '/avatar/cont/7.jpg',
    finish: 1,
  },
  {
    name: 'บริษัท เยลโลลิสซึม จำกัด',
    avatar: '/avatar/cont/8.jpg',
    finish: 2,
  },
  {
    name: 'บริษัท ดีไซน์ดี 49 สตูดิโอ จำกัด',
    avatar: '/avatar/cont/9.jpg',
    finish: 3,
  },
  {
    name: 'บริษัท พีเอดีซี ดีเวลล็อปเมนท์ จำกัด',
    avatar: '/avatar/cont/10.jpg',
    finish: 1,
  },
]




export const dummyData = {
  business_type: 9,
  cat:[],
  contact: {
    name: '',
    id: '',
    phone: '',
    email: '',
    coord: '',
    coord_phone: '',
    address: {
      address:     '',
      subDistrict: '',
      district:    '',
      province:    '',
      zipcode:     '',
    },
  },
  info: {
    detail: '',
    start_year: new Date().getFullYear() + 543, // current year
    capital_registered: '',
    income_per_year: '',
    job_value: '',
    has_factory: '',
    factory_size: '',
    team_size: {
      employee: '',
      engineer: '',
      architect: '',
      foreman: '',
      technician: '',
      worker: '',
    },
  },
  bank: {
    account: '',
    account_no: '',
    name: '',
    branch: '',
  }
};