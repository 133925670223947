import jwt_decode from "jwt-decode";
import { VueCookieNext } from 'vue-cookie-next'

VueCookieNext.config({ expire: '30d' });

// name of member session cookie
const jwtCookieName = process.env.VUE_APP_AUTH_MEMBER;


export default {
  /**
   * set user session when refresh
   *  use this function because store will clear when refresh
   *  so we will use cookie save data too.
   */ 
   detectLogged(state) {
    let authToken = VueCookieNext.getCookie(jwtCookieName);
    // console.log('detectLogged');
    // console.log(authToken);

    if(authToken === '' || authToken === null || authToken === 'undefined') {
      // remove cookie for defense some error
      VueCookieNext.removeCookie(jwtCookieName);
      
      // clear authToken to empty too.
      state.authToken = '';
    } else {
      state.authToken = authToken;

      const token = jwt_decode(authToken);
      state.member = token.data;
    }
  },


  /**
   * Set user session. use when loading
   * 
   * @param {state}
   * @param {jwtToken} 
   */
   setAuthToken(state, jwtToken) {
    // Set JWT token to cookie
    VueCookieNext.setCookie(jwtCookieName, jwtToken, '30d');

    // initial 'logged in' common value to store
    this.commit('detectLogged');
  },
  
  updateAvatar(state, avatar) {
    state.member.avatar = avatar;
  },


  /**
   * Logout function. 
   *  remove user session from cookie
   *  clear user session from store
   */
  logout(state) {
    // console.log('mutation log out')
    state.authToken = '';
    state.member = [];
    VueCookieNext.removeCookie(jwtCookieName);
  },



  /**
   * Change loadingStatus to true
   */
  loadingShow(state) {
    state.loadingStatus = true;
  },

  /**
   * Change loadingStatus to false
   */
   loadingHide(state) {
    state.loadingStatus = false;
  },



  /**
   * Notibar append
   */
  appendNotiBar(state, item) {
    state.notiBarList.push(item);
  },
  
  clearNotiBar(state) {
    state.notiBarList = [];
  },
};