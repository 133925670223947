<script setup>
import JobCode from '../module/JobCode.vue';
import People from '../module/JobPeople.vue';
import JobInterestButton from '../list/JobInterestButton.vue'
import { ref, onMounted, defineProps } from 'vue';

defineProps({ 
  data: Object,
  codeMode: {
    type: String,
    default: 'title'
  }
})

const loaded = ref(false)

onMounted(() => {
  loaded.value = true;
})
</script>


<template>
  <div class="job__head">
    <h2 class="job__head__title">
      {{ data.name }}
    </h2>
    
    <JobCode 
      v-if="data.status > 0"
      :code="data.job_code"
      :mode="codeMode"
      :status="data.status"
      :data="data"
    />
    <JobCode v-else 
      code="รอการอนุมัติ" 
    />
      <!-- style="padding-right: 0; background: none; color: #E55252;" -->

    <ul class="job__people" style="margin-top: 8px">
      <People v-if="data.ae !== 0" :info="data.ae_meta" :showName="true" />
      <People v-if="data.ca !== 0"  :info="data.ca_meta" :showName="true" type="ca" />
      <!-- <People v-if="data.pm !== 0"  :info="data.pm_meta" :showName="true" type="pm" /> -->
      <People v-if="data.winner !== 0"  :info="data.winner_meta" type="contractor" />
    </ul>

    <JobInterestButton 
      v-if=" data.process_status < 6"
      :data=" data " 
    />
  </div>
</template>

<style scoped>
.job-interest {
  bottom: 15px;
}
</style>