<script setup>
/**
 * Title
 *  this component will display 
 *    - page title
 *    - breadcrumb
 */
import Wrap from '@/components/container/WrapComp.vue';
// import Breadcrumb from '@/components/layout/BreadcrumbComp.vue';

import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';


const router = useRouter();
const title = ref('');
const route = ref([]);

/** Cycle and Watch ------------ */
watchEffect(() => {
  route.value = router.currentRoute.value;
  title.value = route.value.meta.title !== undefined ? route.value.meta.title : '';
})

</script>

<template>
  <div class="page__title">
    <!-- <div class="page__title__fg"> -->
      <Wrap>
        <h1 class="page__title__head">
          {{ title }}
        </h1>

        <ul class="breadcrumb">
          <li>
            <a href="/">หน้าหลัก</a>
          </li>
          <li>
            <a :href=" route.fullPath ">
              {{ title }}
            </a>
          </li>
        </ul>
      </Wrap>
    <!-- </div> -->
  </div>
</template>