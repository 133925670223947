import RegisterView from '@/views/login/RegisterView.vue'

export const loginRoute = [
  // Log in
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../../views/login/LoginView.vue'),
    meta: { 
      title: 'เข้าสู่ระบบ'
    }
  },
  
  // Log in with LINE Messenger
  {
    path: '/line-login',
    name: 'LoginLINEView',
    component: () => import('../../views/login/LoginLINEView.vue'),
    meta: { 
      title: 'เข้าสู่ระบบ'
    }
  },

  // Forget password
  {
    path: '/forget-password',
    name: 'ForgetPasswordView',
    component: () => import('@/views/login/ForgetPasswordView.vue'),
    meta: { 
      title: 'ลืมรหัสผ่าน'
    }
  },

  // Customer register
  {
    path: '/register/customer',
    name: 'RegisterCustomerView',
    component: RegisterView,
    meta: { 
      title: 'สมัครเป็นผู้ใช้งาน'
    }
  },

  // Contractor register
  {
    path: '/register/contractor',
    name: 'RegisterContractorView',
    component: RegisterView,
    meta: { 
      title: 'สมัครเป็นผู้รับเหมา'
    }
  },
  
  // Register Policy
  {
    path: '/register/term-of-use/:member_id',
    name: 'RegisterTermView',
    component: () => import('../../views/login/RegisterTermView.vue'),
    meta: { 
      title: 'เงื่อนไขข้อตกลงการใช้บริการของระบบ'
    }
  },
];