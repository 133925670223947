<script setup>
import Icon from '@/components/display/icon/SVGIconComp.vue';
import { ref, onMounted, defineProps } from 'vue'; //
import { useRouter } from 'vue-router';
import { http } from '@/utilities/http';
import currentUse from '@/services/member/currentUser';
import notiAddData from '@/services/noti/notiAddData'
// import { loading } from '@/utilities/display';
// import contInterestJob from '@/services/job/cont/contInterestJob.js';
// import selectContractor from '@/services/job/selectContractor.js'

const moment = require('moment');

const props = defineProps({
  data: Object,
});
const router = useRouter();
const routerName = router.currentRoute.value.name;
const selected = ref(false);
const loaded = ref(false);
const memberId = currentUse('member_id');
const memberRole = currentUse('role');

onMounted(() => {
  props.data.cont.forEach(c => {
    if(memberId === c.member_id) {
      selected.value = true;
    }
  })
  loaded.value = true;
})


const clickInterest = async () => {
  if(props.data.close_interest == 9) {
    alert('ขณะนี้')
    return;
  }
  // loading();
  if(selected.value) {
    const conf = confirm('คุณต้องการยกเลิกการกดสนใจใช่หรือไม่ ?')
    if(!conf) return false;
  }

  selected.value = !selected.value;
  await http
        .post(
          '/job/select/contractor',
          {
            job_id: props.data.job_id,
            member_id: memberId
          }
        )
        .then(async (res) => {
          console.log(res);

          // add noti
          await notiAddData(
            memberId, 
            props.data.job_id, 
            selected.value ? 'contractor_interest' : 'contractor_cancel_interest'
          );
          
          // if this is 'JobDetail' route, this page will refresh
          if(routerName === 'JobDetail') router.go(0);
        })
        .catch((err) => {
          console.error(err);
          alert('บันทึกข้อมูลเกิดข้อผิดพลาด');
        })
}



const calRemainInterestDate = () => {
  if(props.publishDate === '') {
    return -1
  }

  var a = moment();
  var b = moment(props.data.publish_date);
  const diff = props.data.interest_date_limit - a.diff(b, 'days')   // =1
  return diff;
}
</script>


<template>
  <!-- v-if=" loaded && data.surveyor === '0' && memberRole === 'contractor' "  -->
  <div 
    v-if=" 
      loaded && 
      memberRole === 'contractor' && 
      (routerName === 'JobFind' || routerName === 'JobDetail') &&
      data.process_status < 6 && 
      data.close_interest != 9 &&
      calRemainInterestDate() > 0
    " 
    class="job-interest"
  >
    <a 
      @click.prevent=" clickInterest() "
      href="#"
      class="btn"
      :class="{
        'btn--border': selected,
        'orange': selected,
        'btn--active': !selected
      }"
    >
      <span v-if="selected">
        <Icon icon="check-orange" />
        สนใจแล้ว
      </span>
      <span v-else>
        <Icon icon="check" /> 
        สนใจงานนี้
      </span>
    </a>
  </div>
</template>

<style scoped>
.job-interest {
  position: absolute;
  bottom: 18px;
  right: 0;
}
.job-interest .btn {
  padding: 7px 15px 5px 15px;
}
.job-interest .btn--border {
  padding: 5px 13px 3px 13px;
}
</style>