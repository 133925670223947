<script setup>
// import components
import Title            from '@/components/display/text/SectionTitle.vue';
import Box              from '@/components/container/SectionBox.vue';
import Input            from '@/components/display/form/InputRound.vue';
import InputAddr        from '@/components/display/form/InputAddressSet.vue';
import SurveyInfoForm   from '@/components/job/form/JobSurveyInfoForm.vue';
import GalleryUploader  from '@/components/display/file/gallery/GalleryUploaderComp.vue';
import FileUploader     from '@/components/display/file/file/FileUploaderComp.vue';
import ButtonSet        from '@/components/display/form/ButtonSet.vue';

import { ref, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useVuelidate }    from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';

// import value
import { jobDraftData, surverDraft, building_type } from '@/vars/job.js';
import wording from '@/vars/wording.js';

// import api
import jobGetTermsAPI from '@/apis/job/term/jobGetTermsAPI';
// import getJobRequirement from '@/core/service/job/getJobRequirement.js';
// import saveJobRequirement from '@/core/service/job/saveJobRequirement.js';

// import services
import {loading} from '@/utilities/display'; // 
import { http } from '@/utilities/http';
import termPrepareDropdown from '@/services/job/term/termPrepareDropdown'




const store = useStore();
const router = useRouter();
const route = useRoute();
const loaded = ref(false);
const memberId = ref(store.getters.currentUser.member_id);
const jobId = route.params.id;
const addressRef = ref(null);
const state = reactive({ 
  job: {
    ...jobDraftData,
    survey_info: surverDraft,
    survey_status: 9,
  }
});
const catOpt = ref([]);


/**
 * onMounted handled
 */
onMounted( async () => {
  // init draft job state
  state.job.owner = memberId.value;

  // get job categories and convert to option
  await jobGetTermsAPI('cat')
        .then(res => catOpt.value = termPrepareDropdown(res.data))
        .catch(() => alert(wording.api.error.getters));
  if(catOpt.value.length === 0) return; // detect term is empty

  // get job item and prepare data
  await http
        .get('/job/' + jobId + '/full')
        .then(res => {
          let data = res.data;
          console.log(data);
          if(data.requirement.detail !== undefined) {
            state.job.parent_cat    = data.parent_cat === 0 ? '' : data.parent_cat.term_id;
            state.job.detail        = data.requirement.detail;
            state.job.building_type = data.requirement.type;
            state.job.building_type_other = data.requirement.type_other;
            state.job.budget        = data.requirement.budget;
            state.job.urgency       = data.requirement.urgency;
            state.job.location      = data.requirement.location;
            state.job.address       = data.address;
            state.job.status        = data.status;
            
            if(Object.values(data.survey_info).length > 0) {
              state.job.survey_info = data.survey_info;
            }
          }
        })
        .catch(() => alert(wording.api.error.getters));

  // if OK, change page status to 'loaded'
  loaded.value = true;
  loading(false);
})

const v$ = useVuelidate({
  parent_cat:    {required},
  detail:        {required},
  building_type: {required},
  budget:        {required, numeric },
  urgency:       {required},
}, state.job);


/** Method ------------------- */
// on submit form
const onSubmit = async () => {
  // Validate data
  await v$.value.$touch();
  await addressRef.value.v$.$touch();
  if (v$.value.$invalid || addressRef.value.v$.$invalid) {
    alert('คุณกรอกข้อมูลไม่ถูกต้องหรือไม่ครบถ้วน !!!')
    window.scrollTo(0,0);
    return;
  }
  
  loading();
  await http 
        .put('/job/' + jobId, state.job)
        .then(() => {
          // res console.log(res.data);
          // console.log(router);
          router.push('/job/view/' + jobId);
        })
        .catch(() => {
          alert('การบันทึกข้อมูลผิดพลาด กรุณาลองใหม่อีกครั้ง');
        })
  loading(false);
}

// on click cancel button
const onCancel = () => {
  router.push('/');
}
</script>


<template>
  <div v-if="loaded" class="mt--2">
    <form 
      @submit.prevent="onSubmit"
      class="form"
    >
      <Title title="รายละเอียดโครงการ"></Title>
      <fieldset>
        <!--
          General job infomation
        -->
        <Box>
          <div class="row">
            <div class="col col--2">
              <Input 
                v-model="state.job.parent_cat"
                label="ประเภทโครงการ"
                inputType="select"
                placeholder="กรุณาเลือกประเภทโครงการหลัก - -"
                :option="catOpt"
                :required="true"
                :validate="v$.parent_cat"
              />
            </div>
            <div class="col col--2">
              <Input 
                v-model="state.job.building_type"
                label="ประเภทสิ่งก่อสร้าง"
                inputType="select"
                placeholder="กรุณาเลือกประเภทสิ่งก่อสร้าง - -"
                :option="building_type"
                :required="true"
                :validate="v$.building_type"
              />
            </div>
          </div>
          <Input 
            v-if="state.job.building_type == 90"
            v-model="state.job.building_type_other"
            label="กรุณาระบุประเภทสิ่งก่อสร้าง"
            placeholder="กรุณาระบุ"
            class="mb--1"
          />
          
          <Input 
            v-model="state.job.detail"
            inputType="textarea"
            label="ระบุรายละเอียดโครงการ"
            :required="true"
            :validate="v$.detail"
            :rows="5"
          />

          <div class="row">
            <div class="col col--2">
              <Input
                v-model="state.job.urgency"
                label="กำหนดเริ่มดำเนินการ" 
                placeholder="กรุณาเลือกกำหนดการ"
                :required="true"
                :validate="v$.urgency"
              />
            </div>
            <div class="col col--2">
              <Input
                v-model="state.job.budget"
                label="งบประมาณ (ตัวเลขเท่านั้น)"
                placeholder="กรุณากรอกงบประมาณ"
                :required="true"
                :validate="v$.budget"
              />
            </div>
          </div>
          
          <InputAddr 
            v-model="state.job.address" 
            addressLabel="สถานที่ตั้งโครงการ"
            ref="addressRef"
          />
          <Input
            v-model="state.job.location"
            label="ลิ้งค์โลเคชั่น"
            placeholder="กรุณากรอกลิ้งค์โลเคชั่น (อาจจะคัดลอกมาจาก google map หรือ line app)"
          />
        </Box>
        <!-- end: General job infomation -->



        <!--
          OnSite infomation
        -->
        <Title title="รายละเอียดหน้างาน"></Title>
        <Box>
          <SurveyInfoForm
            v-model="state.job.survey_info"
            ref="requestRef"
          />
        </Box>
        <!-- end: OnSite infomation -->




        <Title title="อัพโหลดรูปภาพหน้าโครงการ"></Title>
        <Box>
          <GalleryUploader
            section="job"
            :refer_id="jobId"
            slug="gallery"
          />
        </Box>

        <Title title="แนบไฟล์อื่นๆ"></Title>
        <Box>
          <FileUploader
            section="job"
            :refer_id="jobId"
            slug="attach"
          />
        </Box> 

        <ButtonSet 
          submitTitle="บันทึกข้อมูล"
          :cancelMethod="onCancel"
        />
      </fieldset>
    </form>
  </div>
</template>