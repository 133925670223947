<script setup>
// import components
import JobTitle from '@/components/job/detail/JobTitle.vue'
import Title      from '@/components/display/text/SectionTitle.vue';
import Box        from '@/components/container/SectionBox.vue';
import Input      from '@/components/display/form/InputRound.vue';
import GalleryUploader    from '@/components/display/file/gallery/GalleryUploaderComp.vue';
import ButtonSet from '@/components/display/form/ButtonSet.vue';
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import { DatePicker } from 'v-calendar'; //Calendar, 
import 'v-calendar/dist/style.css';

import { http } from '@/utilities/http';
import { ref, reactive,onMounted } from 'vue';// 
import { useRoute, useRouter } from 'vue-router';
import { loading } from '@/utilities/display';
import progressIdToTitle from '@/services/job/progress/progressIdToTitle'
import notiAddData from '@/services/noti/notiAddData'

const moment = require('moment');
moment.locale("th");
// showLoader(false);


const router = useRouter();
const route = useRoute();
const jobId = route.params.id;
const progressId = route.params.progress_id;

const loaded = ref(false);
const job = ref([]);
const bill = ref([]);
const detailTitle = ref('แผนงานวันนี้');
const state = reactive({
  progress: {
    type: 1,
    title: '',
    detail: '',
    problem: '',
    appointment: null,
    progress_date: null,
    total_worker: 0,
    relate_bill: [],
    status: 9,
  }
});


onMounted( async () => {
  convertRouteNameToProgress();

  await http
        .get('/job/' + jobId)
        .then(res => {
          job.value = res.data;
        })

  job.value.agreement.forEach(aItem => {
    aItem.bill.forEach(item=> {
      if(item.paid_status === 9 && item.withdraw_status === 0) {
        bill.value.push({...item});
      }
    })
  })
  // bill.value = job.value.agreement[0].bill.filter(item => item.paid_status === 9 && item.withdraw_status === 0);

  if (state.progress.type === 10) {
    state.progress.appointment = moment().toDate();
  }
  state.progress.progress_date = moment().format('YYYY-MM-DD HH:mm:ss');

  loaded.value = true;
  loading(false);
})




/** Method ----------- */

// Convert route name to progress type
const convertRouteNameToProgress = () => {
  switch(route.name) {
    case 'AddTimelineViewCheckout':
      state.progress.type = 2;
      detailTitle.value = 'ความคืบหน้าของโครงการ'
    break;
    case 'AddTimelineViewNote':
      state.progress.type = 3;
      detailTitle.value = 'รายละเอียด'
    break;
    case 'AddTimelineViewUpdate':
      state.progress.type = 5;
      detailTitle.value = 'รายละเอียด'
    break;
    case 'AddTimelineViewAppointment':
      state.progress.type = 10;
      detailTitle.value = 'รายละเอียด'
    break;
  }
}
  
// Submit handling
const onSubmit = async () => { 
  let data = {...state.progress};
  if (state.progress.type === 10) {
      data.appointment = moment(state.progress.appointment).format('YYYY-MM-DD HH:mm:ss');
  }
  await http
        .post(
          '/job/'+jobId+'/timeline/'+progressId,
          data
        )
        .then( async (res)=> {
          console.log(res.data)
          console.log(router)

          await notiAddData(
            jobId,
            jobId,
            'progress_update',
            {
              'progress_name': detailTitle.value
            }
          );

          router.push('/job/view/'+jobId+'?step=process')
        })
}
</script>


<template>
  <div 
    v-if="loaded" 
    class="post__job mt--2"
  >
    <router-link :to="'/job/view/' + jobId + '?step=process'">
      กลับไป
    </router-link>
    <JobTitle :data=" job " />

    <WarningCard 
      v-if="state.progress.type === 10 && bill.length === 0" 
      title="ยังไม่มีงวดงานใดที่ท่านเบิกได้"
      text="อาจจะเกิดจากลูกค้ายังไม่ได้ชำระยอดเข้ามาในระบบหรืองวดงานของท่านถูกเบิกไปแล้ว"
    />



    <form 
      v-else
      @submit.prevent="onSubmit"
      class="form"
    >
      <!-- <pre>
        {{state.progress}}
      </pre> -->
      <fieldset>
        <Box>
          <div v-if="state.progress.type === 10">
            <label class="label">งวดที่ส่งมอบ</label>
            <ul class="bill-list">
              <li
                v-for="b in bill"
                :key="b.bill_id"
              >
                <input 
                  v-model="state.progress.relate_bill"
                  type="checkbox" 
                  :value="b.bill_id"  
                />
                <em>{{b.title}}</em>
                {{b.detail}}
              </li>
            </ul>
            <label class="label mt--1">วันนัดตรวจงาน</label>
            <DatePicker 
              v-model="state.progress.appointment"
              mode="dateTime"
              is24hr
            />
          </div>


          <div 
            v-if="state.progress.type == 1 || state.progress.type == 2 || state.progress.type == 5"
            class="highligh-info"
          >
            <strong>
              {{progressIdToTitle(state.progress.type)}}
            </strong> 
            {{ moment(state.progress.progress_date).local('th').format('D MMMM YYYY / HH:mm:ss') }}
          </div>
          <Input 
            v-if="state.progress.type == 1"
            v-model="state.progress.total_worker"
            inputType="number"
            label="จำนวนช่าง"
            :required="true"
          />
          <Input 
            v-if="state.progress.type == 5"
            v-model="state.progress.title"
            label="หัวข้อ"
            :required="true"
          />

          <Input 
            v-model="state.progress.detail"
            inputType="textarea"
            :label="detailTitle"
            :required="true"
            :rows="10"
          />
          <Input 
            v-if="state.progress.type == 2"
            v-model="state.progress.problem"
            inputType="textarea"
            label="อุปสรรคหน้างาน"
            :required="true"
            :rows="10"
          />
          

          <Title title="รูปภาพประกอบ"></Title>
          <GalleryUploader
            section="job"
            :refer_id="jobId"
            :slug="'progress/'+progressId"
          />
        </Box>

        <ButtonSet />
      </fieldset>
    </form>
  </div>
</template>



<style scoped>
.label {
  font-weight: bold;
}
.bill-list li {
  position: relative;
  padding-left: 26px;
}
.bill-list input {
  position: absolute;
  top: 2px;
  left: 0;
}
.bill-list em {
  display: block;
  font-weight: 500;
}
.radio-set span {
  display: inline-block;
}
.radio-set span + span {
  margin-left: 20px;
}
</style>