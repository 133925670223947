import { jobListRoute }     from './job-list-route'
import { jobPostRoute }     from './job-post-route'


import JobView from '@/views/job/JobDetailView.vue'

export const jobRoute = [
  ...jobListRoute,
  ...jobPostRoute,
  
  {
    path: '/job/view/:id',
    name: 'JobDetail',
    component: JobView,
    meta: {
      title: 'รายละเอียดโครงการ'
    }
  },
];