// import jwt_decode from "jwt-decode";

export default {
  /**
   * Get logged in status. if logged in, return true
   */
  loggedIn(state) {
    return state.authToken !== '';
  },
  

  token(state) {
    return state.authToken;
  },

  /**
   * Get current logged in user
   * parse data from authToken (JWT).
   */
  currentUser(state) {
    if(state.authToken === '') return {};
    return state.member;
    // const token = jwt_decode(state.authToken);
    // return token.data;
  },

  /**
   * Get uesr role
   */
  currentUserRole(state, getters) { //
    const user = getters.currentUser;
    let output = user.role.length > 0 ? 'ผู้รับเหมา' : 'ผู้ใช้งาน';
    // console.log(user.role);

    // user.role.forEach((item) => {
    //   switch(item) {
    //     case 'contractor':
    //       output = 'ผู้รับเหมา';
    //     break;
    //     // case 'ca':
    //     //   output = ', CA';
    //     // break;
    //     // case 'ae':
    //     //   output = ', ฝ่ายขาย';
    //     // break;
    //     // case 'surveyor':
    //     //   output = ', นักสำรวจ';
    //     // break;
    //   }
    // })

    return output;
  },

  /**
   * Return global state for show/hide loading 
   */
  showLoading(state) {
    return state.loadingStatus;
  },


  /**
   * Get Noti
   */
  getNotiBarList(state) {
    return state.notiBarList;
  }
}