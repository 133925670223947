<script setup>
import NotiBox from '@/components/display/noti/NotiBox.vue'
import CompareTr   from '../../form/table/CompareTr.vue';
import CompareExtraTr   from '../../form/table/CompareExtraTr.vue';
import ContractorColumnInfo from '@/components/job/module/ContractorColumnInfo.vue';
import Button from '@/components/display/form/ButtonRound.vue';
import SVGIcon from '@/components/display/icon/SVGIconComp.vue';
import WarningCard from '@/components/job/module/JobWarningCard.vue'

import { ref, reactive, onMounted, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { loading } from '@/utilities/display';
import { priceFormat } from '@/utilities/number';
import { http } from '@/utilities/http';
import notiAddData from '@/services/noti/notiAddData'
// const numeral = require('numeral');


const router = useRouter();
const props = defineProps({ 
  data: Object
});
const loaded = ref(false);
const state = reactive({
  boq: {
    child: []
  }
});
const cont = ref([]);

/** Lift Cycle ----------- */
onMounted(() => {
  if(props.data.survey_boq.length > 0) {
    state.boq.child = props.data.survey_boq;
    cont.value = props.data.cont.filter(item => item.proposal_status == 9 && item.show_status == 9 && item.total > 0);
  }
  loaded.value = true;
})




/** Method ----------- */
const onClick = async (item) => {
  loading(); 
  await http
        .patch(
          '/job/' + props.data.job_id, {
            winner: item.member_id,
            process_status: 6,
          }
        )
        .then( async () => {
          await notiAddData(
            props.data.owner,
            props.data.job_id,
            'proposal_select_winner'
          );
          router.go(0)
        })
  
}

const exportData = async () => {
  // alert('-_-');
  loading();
  await http
        .get('/job/' + props.data.job_id + '/export/compare-proposal')
        .then(res => {
          // alert(res.data.url);
          // console.log(res.data)
          window.location.href = res.data.url;
        })
        .catch(e=>{
          console.log(e);
        })

  loading(false);
}
</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step4"
  >
    <WarningCard 
      v-if="data.survey_status != 9" 
    />
    <WarningCard 
      v-else-if="cont.length === 0" 
      icon="clock-gray"
      title="ผู้รับเหมากำลังเสนอราคา"
    />
    <div v-else>
      <NotiBox
        v-if="data.proposal_status == 9 && data.winner == 0"
        title="ผู้ดูแลระบบอนุมัติราคาแล้ว"
        text="ผู้ดูแลระบบตรวจสอบราคาและอนุมัติราคาแล้ว ท่านสามารถกดเลือกผู้ชนะการเสนอราคาได้"
        icon="check-green"
      />

      <div class="mb--2 txt--right">
        <Button
          text="Export Excel"
          class="btn--active btn--export"
          @click="exportData()"
        />
      </div>

      <div class="jtb jtb__proposal">
        <table class="tb">
          <colgroup>
            <col style="width: 50px" />
            <col style="width: 280px" />
            <!-- <col style="width: 80px" /> -->
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">ลำดับ</th>
              <th>รายละเอียด</th>
              <!-- <th>ปริมาณงาน</th> -->
              <td
                v-for="c in cont"
                :key="c"
              >
                <ContractorColumnInfo :data=" c " />

                <span 
                  v-if="data.winner === c.member_id"
                  class="winner--icon"
                >
                  <SVGIcon icon="check-green" />
                  เลือกเจ้านี้แล้ว
                </span>
                <p>
                  <router-link
                    :to="'/job/' + data.job_id + '/proposal/' + c.member_id"
                    target="_blank"
                  >
                    <!-- <SVGIcon icon="file-alt" /> -->
                    ดูรายละเอียด
                  </router-link>
                </p>
              </td>
            </tr>
            

            <CompareTr
              :data="state.boq"
              :cont="cont"
              :index="-1"
              :editable="data.winner == 0"
              parent="-1"
            />
            <CompareExtraTr
            :cont="cont"
            :index="state.boq.child.length"
            :editable="data.winner == 0"
            parent="-1"
          />
          
            <tr class="foot">
              <th scope="row" colspan="2">รวม</th>
              <td v-for="p in cont" :key="p">
                {{ priceFormat(p.total) }} บาท
              </td>
            </tr>
            <tr class="foot">
              <th scope="row" colspan="2">ส่วนลด</th>
              <td v-for="p in cont" :key="p">
                {{ priceFormat(p.discount) }} บาท
              </td>
            </tr>
            <tr class="foot">
              <th scope="row" colspan="2">คงเหลือ</th>
              <td v-for="p in cont" :key="p">
                {{ priceFormat(p.after_discount) }} บาท
              </td>
            </tr>
            <tr class="foot">
              <th scope="row" colspan="2">Vat.</th>
              <td v-for="p in cont" :key="p">
                <span v-if="p.vat_inc">{{ priceFormat(p.vat) }} บาท</span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr class="foot-net">
              <th scope="row" colspan="2">มูลค่างานสุทธิ</th>
              <td v-for="p in cont" :key="p">
                {{ priceFormat(p.net_amount) }} บาท
              </td>
            </tr>
            <tr class="foot">
              <th scope="row" colspan="2">หมายเหตุ</th>
              <td v-for="p in cont" :key="p">
                {{p.remark}}
              </td>
            </tr>
            <tr v-if="data.winner === 0" class="foot-net">
              <th scope="row" colspan="2">&nbsp;</th>
              <td 
                v-for="p in cont" 
                :key="p"
              >
                <div class="form__button form__button--full-width">
                  <Button 
                    @click.prevent="onClick(p)"
                    text="เลือกผู้รับเหมาเป็นผู้ชนะ"
                    icon="check"
                    class="btn--submit"
                  />
                  <slot></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- end: BOQ -->
  </div>
</template>

<style scoped>

.btn--submit {
  font-size: 14px;
}

.winner--icon {
  position: relative;
  padding: 0 0 10px 26px;
  font-weight: bold;
  color: #30B481;
}
.winner--icon .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}
</style>