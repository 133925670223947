<script setup>
import JobGallery from '@/components/job/detail/JobGallery.vue'
import SectionTitle from '@/components/display/text/SectionTitle.vue'
import SectionBox from '@/components/container/SectionBox.vue'
import JobDetail from '@/components/job/module/JobDetail.vue'
import FileAttach from '@/components/job/module/JobFileAttach.vue'
import ButtonRound from '@/components/display/form/ButtonRound.vue'
import NotiBox from '@/components/display/noti/NotiBox.vue'
import InfoRow from '@/components/display/info/InfoRow.vue'
import JobSurveyBoq from '../../form/JobSurveyBoq.vue';

import { ref, reactive, onMounted, defineProps } from 'vue';
import currentUse from '@/services/member/currentUser';
import { facility, surveyCondition } from '@/vars/job';
const memberId = currentUse('member_id');

const props = defineProps({ data: Object });
const loaded = ref(false);
const info = ref([]);
const boq = ref([]);
// const memberRole = currentUse('role');
const state = reactive({ 
  boq: null
});


/** Lift Cycle ----------- */
onMounted(() => {
  // initial survey infomation
  info.value = props.data.survey_info;
  boq.value = props.data.survey_boq;
  state.boq = props.data.survey_boq;

  // prepare facility and condition data
  let facilityList = [];
  let conditionList = [];
  info.value.facility.forEach( item => {
    let facilityItem = facility.find(f => f.id === item);
    if(facilityItem !== undefined) {
      facilityList.push(facilityItem);
    }
  })
  info.value.condition.forEach( item => {
    let conditionItem = surveyCondition.find(f => f.id === item);
    if(conditionItem !== undefined) {
      conditionList.push(conditionItem);
    }
  })
  info.value.facility = facilityList;
  info.value.condition = conditionList;
  

  loaded.value = true;
})
</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step1"
  >
    <NotiBox
      v-if="data.status != 9"
      title="งานของท่านกำลังอยู่ระหว่างอนุมัติข้อมูล"
      text="ผู้ดูแลระบบกำลังตรวจสอบข้อมูลของท่าน หากสงสัยสามารถติดต่อเจ้าหน้าที่เพิ่มเติมได้"
      icon="clock-orange"
    />

    <div 
      v-if="data.owner === memberId && data.status == 1"
      class="mt--2 mb--2 txt--right"
    >
      <ButtonRound
        :routePath="'/job/edit/' + data.job_id" 
        type="button"
        text="แก้ไข"
        icon="edit-file"
        class="btn--active btn--s"
      />
    </div>

    <JobGallery 
      :image=" data.gallery " 
    />

    <SectionTitle title="รายละเอียดงาน" class="mt--3" />
    <SectionBox>
      <JobDetail :data=" data " />
    </SectionBox>
    
    
    <SectionTitle title="รายละเอียดหน้างาน" class="mt--4" />
    <SectionBox>
      <div class="row">
        <InfoRow title="วัน/เวลาทำงาน" class="col col--2">
          {{ info.work_day }} {{ info.work_time }}
        </InfoRow>
        <InfoRow title="ทางเข้าขนาด" class="col col--2">
          {{info.gate_width}} เมตร
        </InfoRow>
      </div>
      <div class="row">
        <InfoRow title="สาธารณูปโภคภายใน" class="col col--2">
          <span v-if="info.facility.length === 0">
            ไม่มีสาธารณูปโภคใดๆ เลย
          </span>
          <span v-else class="job__term">
            <span 
              v-for="item in info.facility" 
              :key="item"
              class="item"
            >
              {{ item.name }}
            </span>
          </span>
        </InfoRow>
        
        <InfoRow title="เงื่อนไขการทำงาน" class="col col--2">
          <span v-if="info.condition.length === 0">
            ไม่มีเงื่อนไขใดๆ เลย
          </span>
          <span v-else class="job__term">
            <span 
              v-for="item in info.condition" 
              :key="item"
              class="item"
            >
              {{ item.name }}
            </span>
          </span>
        </InfoRow>
      </div>
      
      <InfoRow title="อุปสรรค์หน้างาน">
        <span v-if="info.obstacle === ''" class="info-nodata">
          (ไม่มีข้อมูล)
        </span>
        <span v-else>
          {{ info.obstacle }}
        </span>
      </InfoRow>

      <InfoRow title="ลักษณะพื้นที่ข้างเคียง">
        <span v-if="info.condition === ''" class="info-nodata">
          (ไม่มีข้อมูล)
        </span>
        <span v-else>
          {{ info.envelopment }}
        </span>
      </InfoRow>
    
      <InfoRow title="อื่นๆ">
        <span v-if="info.other === ''" class="info-nodata">
          (ไม่มีข้อมูล)
        </span>
        <span v-else>
          {{ info.other }}
        </span>
      </InfoRow>

      <InfoRow title="ความคิดเห็นจากผู้เข้าสํารวจ">
        <span v-if="info.surveyor_opinion === ''" class="info-nodata">
          (ไม่มีข้อมูล)
        </span>
        <span v-else>
          {{ info.surveyor_opinion }}
        </span>
      </InfoRow>
    </SectionBox>
    

    <FileAttach :file=" data.attach " class="mt--4"  />
    
    <div v-if=" state.boq.length > 0 ">
      <SectionTitle title="บัญชีแสดงปริมาณวัสดุ (BOQ)" class="mt--4" />
      <JobSurveyBoq
        v-model="state.boq"
        :editable="false"
      />
    </div>
  </div>
</template>