<script setup>
import JobStep        from '@/components/job/detail/JobStep.vue'
import JobTitle       from '@/components/job/detail/JobTitle.vue'
import Step1          from '@/components/job/detail/step/Step1__General.vue'
import Step2          from '@/components/job/detail/step/Step2__SelectCont.vue'
import Step5Compare   from '@/components/job/detail/step/Step5__Compare.vue'
import Step5Proposal  from '@/components/job/detail/step/Step5__Proposal.vue'
import Step6          from '@/components/job/detail/agreement/AgreementList.vue'
import Step7          from '@/components/job/detail/step/Step7__Progress.vue'
import Step8          from '@/components/job/detail/step/Step8__Review.vue'
import WarningCard    from '@/components/job/module/JobWarningCard.vue'
import NotiBox        from '@/components/display/noti/NotiBox.vue';
import { ref, onMounted } from 'vue';
import { useRoute,useRouter } from 'vue-router'; //, useRouter
import { jobStep } from '@/vars/job'
import { http } from '@/utilities/http'
import currentUser from '@/services/member/currentUser'
import { surverDraft, surverBoqDraft } from '@/vars/job.js';


/** Initial variables ----------- */
const route = useRoute();
const router = useRouter();
const jobId = route.params.id;
const loaded = ref(false);
const step = ref(1);
const stepList = ref([]);
const data = ref([]);
const memberId = currentUser('member_id');
const memberRole = ref(currentUser('role'));
const memberContVerify = currentUser('contractor_verify');
const myCont = ref([]);


/** Life Cycle ----------- */
onMounted( async () => {
  if(memberRole.value == 'contractor' && memberContVerify < 9) {
    router.push('/profile/contractor')
  }

  //// load full job detail from api
  await http
        .get('/job/' + jobId + '/full')
        .then((res) => data.value = res.data)
        .catch(() => alert('การดึงข้อมูลผิดพลาด กรุณาลองรีเฟรชหน้านี้ใหม่อีกครั้ง'));
  // prepare boq and surver info for old data
  if(data.value.survey_info.length === 0 ) {
    data.value.survey_info = surverDraft;
    data.value.survey_boq = surverBoqDraft;
  }
  
  //// inital and find tab when refresh
  stepList.value = jobStep;
  if(route.query.step !== undefined) {
    stepList.value.forEach((item, index) => {
      if(route.query.step === item.key) {
        step.value = index+1;
      }
    });
  }

  //// if they have contractor role, 
  //// get they own interest info and status
  if(memberRole.value === 'contractor') {
    myCont.value = data.value.cont.find(item => item.member_id == memberId);
  }
  
  //// show this page
  loaded.value = true;
})



/** Method ----------- */
const changeStep = (nextStep) => {
  step.value = nextStep
}

const detectPermission = () => {
  if(memberRole.value === 'contractor') {
    //// when you're contractor
    //// if you have interested and customer choice you, you can view more than step 4
    //// or if you're winner you can view step 7 and more
    return (step.value > 4 && (myCont.value === undefined || myCont.value.owner_accept != 9)) ||
           (step.value >= 6 && data.value.winner != memberId);
  } else {
    //// when you're customer. you can view step 7 if you choice winner
    return data.value.process_status < 7 && (step.value >= 7 || step.value == 8);
  }
}
</script>

<template>
  <div v-if="loaded" class="job__view">
    <!-- 
      Page title and notibox
    -->
    <JobTitle :data=" data " />
    <NotiBox
      v-if="data.status == 5"
      title="โครงการนี้ถูกปิดแล้ว"
      :text="data.closed_remark"
      icon="warning-orange"
    />
    <NotiBox
      v-if="data.close_interest == 9"
      title="โครงการนี้มีผู้สนใจเต็มจำนวนแล้ว"
      text="ขออภัย ขณะนี้โครงการได้มีผู้สนใจเต็มจำนวนที่ระบบกำหนดแล้ว"
      icon="warning-orange"
    />

    <!-- 
      detail tab
    -->
    <JobStep 
      v-if="data.status > 1"
      :currentStep=" step " 
      @change-step=" changeStep "
      :data="data"
    />

    <!-- 
      Page content
    -->
    <WarningCard 
      v-if=" detectPermission() " 
      title="คุณยังดำเนินการไม่ถึงขั้นตอนนี้"
    />
    <Step1 
      v-else-if="step === 1" 
      :data=" data "
    />
    <Step2
      v-else-if=" step === 2 && memberRole === 'customer' " 
      :data=" data "
    />
    <div v-else-if="step === 5">
      <WarningCard 
        v-if="data.process_status < 5"
      />
      <Step5Compare
        v-else-if="memberRole === 'customer' " 
        :data=" data "
      />
      <Step5Proposal
        v-else-if="memberRole === 'contractor' " 
        :data=" data "
      />
    </div>
    <Step6 
      v-else-if="step === 6"
      :data=" data " 
    />
    <Step7
      v-else-if="step === 7" 
      :data=" data "
    />
    <Step8 
      v-else-if="step === 8" 
      :data=" data "
    />
    <WarningCard 
      v-else
      title="ไม่พบข้อมูลในหัวข้อนี้"
    />
  </div>
</template>