<script setup>
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import ContractorInfo from '@/components/job/module/ContractorBoxInfo.vue'
import Title      from '@/components/display/text/SectionTitle.vue';
import ApproveStatus from '@/components/job/detail/agreement/module/ApproveStatusList.vue';
import ButtonRound from '@/components/display/form/ButtonRound.vue';

import { ref, defineProps, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import currentUser from '@/services/member/currentUser'
import { http } from '@/utilities/http';
import { loading } from '@/utilities/display';



const router = useRouter();
const props = defineProps({
  data: Object
})
const loaded = ref(false);
// const memberId = currentUser('member_id');
const memberRole = ref(currentUser('role'));


/** Lift Cycle ----------- */
onMounted(() => {
  loaded.value = true
})



/** Method ----------- */
const createDraftAgreement = async () => {
  loading();
  await http
        .post('/job/' + props.data.job_id + '/agreement/draft')
        .then(res => {
          console.log(res.data);
          router.push('/job/' + props.data.job_id + '/agreement/'+res.data.insert_id);
        })
}
</script>


<template>
  <div 
    v-if=" loaded "
    class="job__step6"
  >
    <!-- if this job haven't the winner -->
    <WarningCard v-if="data.winner === 0" />
    <div v-else>
      <ContractorInfo :info="data.winner_meta" />
      <Title title="รายการสัญญา" />
      <div 
        v-for="item in data.agreement"
        :key="item"
        class="
          data__box 
          agreement__item 
          mt--2
        "
      >
        <div class="inner">
          <figure class="icon">
            <img src="@/assets/images/icon/file-edit.svg" />
          </figure>
          <h4 class="head">
            {{ item.name }}
          </h4>

          <ApproveStatus :data="item" />

          <ButtonRound 
            :routePath="'/job/' + data.job_id + '/agreement/' + item.agreement_id"
            text="ดูข้อมูล"
            icon="file-alt"
            class="btn--active"
          />
        </div>
      </div>

      <div v-if=" memberRole== 'contractor' " class="mt--4" style="display:none">
        <ButtonRound
          @click="createDraftAgreement"
          text="สร้างสัญญางานเพิ่มลด" 
          icon="file-edit"
          class="
            btn--border
            orange
          "
        />
      </div>

    </div>
  </div>
</template>

<style scoped>
.btn--active {
  position: absolute;
  top: 0;
  right: 0;
}
</style>