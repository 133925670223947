<script setup>
/**
 * Title
 *  this component will display 
 *    - page title
 *    - breadcrumb
 */
import Wrap from '@/components/container/WrapComp.vue';
// import Box from '@/components/container/SectionBox.vue';
// import Breadcrumb from '@/components/layout/BreadcrumbComp.vue';

import { onMounted, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// import currentUser from '@/services/member/currentUser';
import ContractorBoxInfo from '@/components/job/module/ContractorBoxInfo.vue'
import { http } from '@/utilities/http';


const router = useRouter();
const uRoute = useRoute();
const route = ref([]);
const title = ref('');
const member = ref([]);//currentUser('all');

/** Cycle and Watch ------------ */
onMounted(async () => {
  let memberId = uRoute.params.member_id;

  // get contractor info
  await http
        .get('/member/' + memberId)
        .then((res) => {
          member.value = res.data
        })
        .catch((err) => {
          console.log(err);
          alert('ไม่สามารถเปิดข้อมูลของท่านได้ กรุณาลองรีเฟรชใหม่อีกครั้ง !!!');
        })
})
watchEffect(() => {
  route.value = router.currentRoute.value;
  title.value = route.value.meta.title !== undefined ? route.value.meta.title : '';
})

</script>

<template>
  <div class="page__title page__title__contractor">
    <Wrap>
      <ul class="breadcrumb">
        <li>
          <a href="/">หน้าหลัก</a>
        </li>
        <li>
          <a :href=" route.fullPath ">
            {{ title }}
          </a>
        </li>
      </ul>
      <h1 class="page__title__head">
        {{ title }}
      </h1>

      <ContractorBoxInfo 
        :info="member" 
        boxStyle="box" 
        class="mt--1" 
      />
    </Wrap>
  </div>
</template>

<style scoped>
.page__title__contractor {
  margin-bottom: 75px;
}
.page__title__contractor > .wrap {
  padding: 0 60px;
}
</style>