/**
 * This script for initial all route and auth member
 */
import { createRouter, createWebHistory } from 'vue-router'
import beforeEachRoute from './utils/beforeEachRoute';

/**
 * Import route datas and merge array
 */ 
import { homeRoute }    from './routes/home-route.js'
import { jobRoute }     from './routes/job'
import { loginRoute }   from './routes/login-route.js'
import { memberRoute }  from './routes/member-route.js'
import { notiRoute }    from './routes/noti-route.js'
// import { paymentRoute } from './routes/payment-route'
// import { pageRoute }    from './routes/page-route.js'

const routes = [
  ...loginRoute,
  ...jobRoute,
  ...memberRoute,
  ...homeRoute,
  ...notiRoute,
];



/**
 * Initial router
 */ 
// create route variable
const router = createRouter({
  routes,
  // mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
})

// initial router guards
router.beforeEach( async (to, from, next) => await beforeEachRoute(to, from, next));


/** export to global */
export default router
