<script setup>
import InfoRow from '@/components/display/info/InfoRow.vue'

import { defineProps } from 'vue';
import buildingType from '@/services/job/buildingType'
import addressFormat     from '@/utilities/format/addressFormat'
import nl2br     from '@/utilities/format/nl2br'
const numeral = require('numeral');

defineProps({ 
  data: Object,
  hideWrap: {
    type: Boolean,
    default: false,
  }
});
</script>

<template>
  <div>
    <div class="row">
      <InfoRow title="ประเภทงาน" class="col col--2">
        {{ data.parent_cat.name }}
      </InfoRow>
      <InfoRow title="ประเภทสิ่งก่อสร้าง" class="col col--2">
        {{ buildingType( data.requirement.type ) }}
        <span v-if="data.requirement.type == 90">
          - {{data.requirement.type_other}}
        </span>
      </InfoRow>
    </div>

    <InfoRow title="รายละเอียดงาน">
      <div v-html="nl2br(data.requirement.detail)"></div>
    </InfoRow>
    
    <div class="row">
      <InfoRow title="กำหนดเริ่มดำเนินการ" class="col col--2">
        {{ data.requirement.urgency }}
      </InfoRow>
      <InfoRow title="งบประมาณ" class="col col--2">
        {{ numeral(data.requirement.budget).format('0,0') }} บาท
      </InfoRow>
    </div>

    <InfoRow title="ที่อยู่">
      {{ addressFormat(data.address) }}
      <span 
        v-if="data.requirement.location !== undefined && data.requirement.location !== ''"
        class="map-link"
      >
         <a :href="data.requirement.location" target="_blank">ดูแผนที่</a>
      </span>
    </InfoRow>
  </div>
</template>

<style scoped>
.map-link {
  display: block;
}
.map-link a {
  text-decoration: underline;
  color: #3399cc;
}
</style>