<script setup>
import NotiBox from '@/components/display/noti/NotiBox.vue'
import ProgressList from '@/components/job/detail/progress/ProgressList.vue'
// import GanttBlock from '@/components/job/detail/progress/GanttBlock.vue'
import BillList from '@/components/job/detail/progress/BillList.vue'

import { ref, onMounted, defineProps } from 'vue';
import currentUser from '@/services/member/currentUser';


const props = defineProps({ data: Object });
const loaded = ref(false);
const ganttData = ref([]);
const agreement = ref([]);
const memberRole = currentUser('role');

/** Lift Cycle ----------- */
onMounted(() => {
  if(props.data.agreement[0] !== undefined || props.data.agreement[0].boq_gantt.length > 0) {
    agreement.value = props.data.agreement;
    ganttData.value = agreement.value.boq_gantt;
  }
  loaded.value = true;
})
</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step6"
  >
    <NotiBox 
      v-if=" data.process_status == 8 "
      title="การก่อสร้างเสร็จสิ้น"
      :text="'ขณะนี้งานก่อสร้างนี้ได้ดำเนินการเสร็จเรียบร้อยแล้ว ' + (memberRole=='customer' && data.review.length === 0 ? 'ท่านสามารถให้คะแนนรีวิวผู้รับเหมาในขั้นตอนต่อไปได้' : '')"
      :icon=" memberRole=='customer' && data.review.length === 0 ? 'alert-orange' : 'check-green' "
    />

    <ProgressList :data="data" />
    <BillList     :data="agreement" class="mt--3" />    
    <!-- <GanttBlock   :data="ganttData" class="mt--3" /> -->
  </div>
</template>