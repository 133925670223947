export const memberRoute = [
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/member/ProfileView.vue'),
    meta: {
      title: 'ข้อมูลผู้ใช้งาน'
    }
  },
  {
    path: '/profile/contractor',
    name: 'ProfileContractor',
    component: () => import('@/views/member/contractor/ContEditView.vue'),
    meta: {
      title: 'ข้อมูลผู้ใช้งาน'
    }
  },
  {
    path: '/contractor/:member_id',
    name: 'PreviewContractor',
    component: () => import('@/views/member/contractor/ProfileContView.vue'),
    meta: {
      title: 'แนะนำผู้รับงาน'
    }
  },


  
  
  {
    path: '/contractor/term',
    name: 'ContractorTermView',
    component: () => import('@/views/member/contractor/ContractorTermView.vue'),
    meta: {
      title: 'เงื่อนไขการรับงาน'
    }
  },
  {
    path: '/contractor/term/confirmation',
    name: 'ContractorTermConfirmView',
    component: () => import('@/views/member/contractor/ContractorTermConfirmView.vue'),
    meta: {
      title: ''
    }
  },
];