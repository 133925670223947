<script setup>
import ContractorBoxInfo from '@/components/job/module/ContractorBoxInfo.vue'
import ProgressAddBtnSet from './ProgressAddBtnSet.vue'
import TimelineItem from '@/components/job/timeline/TimelineItem.vue';
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import ProgressListPopup from './ProgressListPopup.vue';

import { ref, onMounted, defineProps } from 'vue';
import { http } from '@/utilities/http';
import { loading } from '@/utilities/display';
import { useRouter } from 'vue-router';
import currentUser from '@/services/member/currentUser';
import progressIdToTitle from '@/services/job/progress/progressIdToTitle'
import nl2br from '@/utilities/format/nl2br';
import notiAddData from '@/services/noti/notiAddData'

const moment = require('moment');
moment.locale("th");


const router = useRouter();
const props = defineProps({ data: Object });
const loaded = ref(false);
const cont = ref([]);
const memberRole = currentUser('role');



/** Lift Cycle ----------- */
onMounted(() => {
  cont.value = [];
  
  props.data.progress.forEach(item => {
    item.format = {
      date: moment(item.progress_date).format('DD.MM.YYYY'), 
      time: moment(item.progress_date).format('HH:mm'),
      title: progressIdToTitle(item.type),
      // sub_title: '',
    }
    if(item.type == 5) {
      item.format.sub_title = item.title
    }
    if(item.type == 10) {
      item.format.sub_title= moment(item.appointment).format('นัดตรวจงาน วันที่ D MMM YYYY  เวลา HH.mm น.');
    }
  })

  loaded.value = true;
})


/** Method ----------- */
const updateStatus = async (id, status) => {
  const job_id = props.data.job_id;

  loading()
  await http
        .post(
          `/job/${job_id}/timeline/${id}`, 
          { owner_approve: status }
        )
        .then( async (res) => {
          console.log(res.data);
          console.log(router);

          await notiAddData(
            props.data.owner,
            props.data.job_id,
            'withdraw_customer_' + (status == 9 ? 'approved':'canceled')
          );
          router.go(0)
        })
  loading(false)
}
</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step6"
  >
    <ProgressAddBtnSet
      v-if="memberRole==='contractor' && data.process_status == 7 "
    />

    <ContractorBoxInfo :info="data.winner_meta" class="had-slot">
      <WarningCard
        v-if="data.progress.length === 0"
        icon="clock-gray"
        title="ยังไม่มีข้อมูลความคืบหน้า"
      />
      <div 
        v-else
        class="timeline__wrap"
      >
        <TimelineItem
          v-for=" (item, index) in data.progress "
          :key="item"
          :data=" item.format "
          :isLast=" index === data.progress.length - 1 "
        >
          <div 
            v-if="item.type === 1"
            class="highligh-info"
          >
            <strong>จำนวนช่าง</strong> 
            {{ item.total_worker }} คน
          </div>
          <div 
            v-else-if="item.type === 10"
            class="highligh-info"
          >
            <strong>งวดงาน</strong>
            <ul class="bill--item">
              <li
                v-for="bill in item.relate_bill"
                :key="bill.id"
              >
                {{ bill.title }}
              </li>
            </ul>
          </div>


          <strong v-if="item.type == 1">
            แผนงานวันนี้
          </strong>
          <strong v-else-if="item.type == 2">
            ความคืบหน้าของงาน
          </strong>
          <!-- <strong v-else>
            รายละเอียด
          </strong> -->

          <!-- 
            Item detail 
          -->
          <p 
            v-html="nl2br(item.detail)"
          />
          <div 
            v-if="item.type == 2"
            class="mt--1"
          >
            <p>
              <strong>อุปสรรคหน้างาน</strong>
            </p>
            <p v-html="nl2br(item.problem)" />
          </div>

          <!-- gallery -->
          <ProgressListPopup
            v-if="item.gallery !== undefined"
            :gallery="item.gallery"
          />


          <div 
            v-if="item.type == 10" 
          >
            <div class="withdraw__status mb--2">
              <span v-if="item.admin_approve == 9" class="tsta">
                <img src="@/assets/images/job/btn/check.svg" /> 
                ผู้ดูแลระบบอนุมัติแล้ว
              </span>
              <span v-else-if="item.admin_approve == 1" class="tsta">
                <img src="@/assets/images/progress/cancel.svg" /> 
                ผู้ดูแลระบบไม่อนุมัติ
              </span>
              <span v-else  class="tsta">
                <img src="@/assets/images/bill/bill-wait.svg" /> 
                รอผู้ดูแลระบบอนุมัติ
              </span>
              

              <span v-if="item.owner_approve == 9"  class="tsta">
                <img src="@/assets/images/job/btn/check.svg" /> 
                ลูกค้าอนุมัติแล้ว
              </span>
              <span v-else-if="item.owner_approve == 1"  class="tsta">
                <img src="@/assets/images/progress/cancel.svg" /> 
                ลูกค้าไม่อนุมัติ
              </span>
              <span v-else  class="tsta">
                <img src="@/assets/images/bill/bill-wait.svg" /> 
                รอลูกค้าอนุมัติ
              </span>
            </div>
            

            
            <div 
              v-if="
                memberRole === 'customer' &&
                item.owner_approve == 0 &&
                item.admin_approve != 1
              "
              class="timeline-btn mt--2"
            >
              <a 
                @click.prevent="updateStatus(item.progress_id, 9)"
                href="#"
                class="pb-col"
              >
                <img src="@/assets/images/progress/btn-approve.svg" />
              </a>
              <a 
                @click.prevent="updateStatus(item.progress_id, 1)"
                href="#"
                class="pb-col"
              >
                <img src="@/assets/images/progress/btn-revise.svg" />
              </a>
            </div>
          </div>
        </TimelineItem>
      </div>
    </ContractorBoxInfo>
  </div>
</template>

<style scoped>
.timeline-btn {
  display: flex;
}
.timeline-btn a {
  margin-right: 10px;
}

.bill--item {
  margin-bottom: 10px;
  padding-left: 20px;
}
.bill--item li {
  list-style-type: disc;
}
</style>