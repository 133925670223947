<script setup>
// import components
// import Header     from '@/components/layout/HeaderComp.vue';
import Wrap       from '@/components/container/WrapComp.vue';
// import Footer     from '@/components/layout/FooterComp.vue';
import Title      from '@/components/layout/TitleComp.vue';
import TitleCont  from '@/components/layout/TitleContractorComp.vue';
import Sidebar    from '@/components/layout/SidebarComp.vue';
import Loading    from '@/components/display/LoadingOverlayComp.vue';

import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

// load global css
import './assets/css/font/awesome/style.css';
import './assets/css/style.css';

const store = useStore();
const router = useRouter();
const currentRoute = ref('');

watchEffect(() => {
  currentRoute.value = router.currentRoute.value.name
});

const hasLoggedInStatus = () => {
  return store.getters.loggedIn && currentRoute.value != 'PreviewContractor';
}
</script>


<template>
  <!-- <Header></Header> -->
  
  <Title v-if=" hasLoggedInStatus() "></Title>
  <TitleCont v-else-if=" currentRoute == 'PreviewContractor' "></TitleCont>

  <Wrap>
    <!-- 
      add layout class for show sidebar after login complete only
    -->
    <div 
      :class="{ 
        layout: hasLoggedInStatus() 
      }"
    >

      <div 
        v-if=" hasLoggedInStatus() " 
        class="layout__sidebar"
      >
        <Sidebar></Sidebar>
      </div>

      <div 
        :class="{ 
          layout__main: hasLoggedInStatus() 
        }"
      >
        <Wrap class="main__container">
          <router-view />
        </Wrap>
      </div>

    </div>
  </Wrap>

  <!-- <Footer></Footer> -->

  <Loading />
</template>
