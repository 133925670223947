<script setup>
// import CompareTr from './CompareTr.vue';
import { ref, onMounted, defineProps } from 'vue'; //, computed
// import { toFloat } from '@/utilities/number';

// const numeral = require('numeral');
const props = defineProps({
  index: Number,
  parent: String,
  data: Object,
  cont: Object,
  editable: {
    type: Boolean,
    default: true
  }
});
// const item = ref([]);
// const childParent = ref('');
const globalIndex = ref(0);
const boq = ref([]);

/** Life Cycle -----------------------*/
onMounted(() => {
  globalIndex.value = props.index + 1;
  
  props.cont.forEach(item => {
    item.boq_extra.forEach(boqItem => {
      boqItem.index = globalIndex.value;
      globalIndex.value++;

      boq.value.push(boqItem);
    })
  })
})

const showSpecInfo = (spec) => {
  alert(spec);
}
</script>

<template>
  <!-- <tbody 
    v-for="contractor in cont"
    :key="contractor.member_id"
  > -->
    <tr 
      v-for="item in boq"
      :key="item.proposal_id"
    >
      <td>
        <div class="pl--0">
          {{ item.index }}
        </div>
      </td>
      <td>
        {{ item.name }}
      </td>
      <!-- <td>
        {{ item.value }} 
        {{ item.unit }}
      </td> -->
      
      <!-- ราคารวมที่ผู้รับเหมาเสนอ -->
      <td
        v-for=" c in cont " 
        :key="c.member_id"
        class="col--price"
      > 
        <div v-if=" c.member_id == item.relate_member ">
          {{ item.total }} บาท
          <span 
            v-if="item.spec !== ''"
            class="description"
          >
            <a href="#" @click.prevent="showSpecInfo(item.spec)">
              <i class="las la-info-circle" style="font-size: 16px;"></i>
            </a>
          </span>
        </div>
        <div v-else>-</div>
      </td>
    </tr>
  <!-- </tbody> -->

  <!-- <CompareTr
    v-for="(child, childIndex) in item.child"
    :key="child"
    :data="child"
    :cont="cont"
    :index="childIndex"
    :parent="parent === '-1' ? '' : childParent + (index+1)"
    :editable="editable"
  /> -->
</template>