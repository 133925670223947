<script setup>
// import Avatar from '@/components/display/image/AvatarComp.vue';
import { ref, onMounted, defineProps } from 'vue';
import ContractorBadge from '@/components/member/contractor/ContractorBadge.vue';

defineProps({
  info:Object,
  boxStyle: {
    type: String,
    default: 'default'
  }
})
// const route = useRoute();
const loaded = ref(false);


/** Life Cycle ----------- */
onMounted(() => {
  loaded.value = true;
})
</script>


<template>
  <div 
    v-if="loaded" 
    class="cont__box"
    :class="{
      cont__box__shadow: boxStyle === 'box'
    }"
  >
    <div class="inner">
      <figure class="logo">
        <img v-if="info.avatar != ''" :src="info.avatar" />
        <img v-else src="@/assets/images/scale/1-1.png" />
      </figure>
      <h4 class="name">
        <a href="#">{{ info.full_name }}</a>
      </h4>
      <img 
        src="@/assets/images/member/verified-status.svg" 
        class="mt--1" 
        style="margin-bottom: 3px; opacity: 0.0" 
      />
      <ContractorBadge :data="info.badge_meta" />
    </div>
    <slot></slot>
  </div>
</template>


<style scoped>
.cont__box__shadow {
  border: none;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
}
</style>