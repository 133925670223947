<script setup>
/**
 * Job step
 *  - display step
 *  - detect step route when page refresh
 */
import {ref, onMounted, defineProps, defineEmits, watchEffect} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { jobStep } from '@/vars/job'
import currentUse from '@/services/member/currentUser';

const router = useRouter();
const route = useRoute();

const emits = defineEmits(['change-step']);
const props = defineProps({
  data: Object,
  currentStep: Number
})
const step = ref(1);
const stepList = ref([]);
// const memberId = currentUse('member_id');
const memberRole = currentUse('role');
const jobFixStep = ref(1);

/** Life cycle ----------- */
onMounted(() => {
  jobFixStep.value = props.data.process_status;

  if(memberRole == 'customer') {
    jobStep[7].title = 'ในคะแนนผู้รับเหมา';
  }
  
  stepList.value = jobStep;
  step.value = props.currentStep;
})

watchEffect(() => {
  jobFixStep.value = props.data.process_status;
})


/** Method ----------- */
const gotoStep = (stepNumber) => {  
  step.value = stepNumber + 1;

  // change route query
  const query = Object.assign({}, route.query);
  query.step = stepList.value[stepNumber].key;
  router.push({ query });

  // emit to parent
  emits('change-step', step.value);
}

const stepNumFormat = (index) => {
  if (memberRole == 'contractor') {
    return '0' + (index >= 1 ? index-2 : index+1);
  } else {
    return '0' + ((index >= 2 ) ? index-1 : index+1);
  }
}
</script>
<template>
  <div class="job__step">
    <ul class="inner">
      <li 
        v-for="(item, index) in stepList"
        :key="index"
        class="job__step__item" 
        :class="{ 
          current: (index+1) == jobFixStep,
          pass: index < jobFixStep-1,
          active: (index+1) == step,
          'hide-item': (index === 1 && memberRole== 'contractor') || index === 2 || index === 3
        }"
      >
        <a 
          @click.prevent="gotoStep(index)"
          class="job__step__link"
          href="#" 
        >
          <div class="bullet"></div>
          <div class="num">
            {{ stepNumFormat(index) }}
          </div>
          <strong>{{ item.title }}</strong>
          {{ item.detail }}
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.hide-item {
  display: none;
}
</style>