/**
 * 
 * @description
 *    This function write for router guard before change route to next page
 * 
 * @update 
 *    25 may 2023
 * 
 */

// import vue core
import store from '@/store'

// import services, utilities and variables
import getAuthen from '@/services/member/authen/getAuthen.js';
import authenSetBackLink from '@/services/member/authen/authenSetBackLink.js';
import {publicRoutePath, publicRouteName} from '../vars';


export default async function beforeEachRoute(to, from, next) {

  // Clear section ------------------------
  store.commit('clearNotiBar'); // clear all noti

  // Authorization section ------------------------
  //  Detect current route is public or not by route path and route name
  //  if it's not public and check authen false,
  //  router will redirect to 'Login' page
  if(!publicRoutePath.includes(to.path) && !publicRouteName.includes(to.name)) {
    // if auth is empty, redirect to login
    const authStatus = await getAuthen();
    if (!authStatus) {
      await authenSetBackLink(to.path);
      next('/login');
    }
  }


  // Initial next route section ------------------------
  // set page title
  document.title = ((to.meta.title !== undefined) ? 
                      to.meta.title : 
                      'ศูนย์รวม ผู้รับเหมาก่อสร้าง ครบวงจร') + " | Beaverman";


  // if OK, go to next page
  next();
}