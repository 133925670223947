import { http } from "@/utilities/http";

export default async function getSettingByKey(key = '') {
  if(key === '') return {};
  
  let result = {};
  await http
        .get('/setting/' + key)
        .then(res => {
          result = res.data.json.length > 0
                    ? res.data.json
                    : res.data.data;
        })
        .catch(() => {
          alert('เกิดข้อผิดพลาด ไม่สามารถโหลดข้อมูลประเภทงานได้');
        });
  return result
}