import {jobProgress} from '@/vars/job';

export default function progressIdToTitle(id) {
  const result = jobProgress.find(item => item.id == id);

  if (result === undefined) {
    return result;
  } else {
    return result.name;
  }
}