<script setup>
import { ref, defineProps, onMounted } from 'vue';
import Avatar from '@/components/display/image/AvatarComp.vue';
import FontIcon from '@/components/display/icon/FontIconComp.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'ae'
  },
  info: Object,
  showName: {
    type: Boolean,
    default: false
  },
});

const title = ref('');
const avatar = ref('');

onMounted(() => {
  if (props.info !== undefined) {
    avatar.value = props.info.avatar;
  }
  switch(props.type) {
    default:
      title.value = 'ผู้ดูแลโครงการ';//'ฝ่ายขาย';
    break;
    case 'owner':
      title.value = 'ลูกค้า';
    break;
    case 'ca':
      title.value = 'วิศวกรที่ปรึกษา';
    break;
    case 'pm':
      title.value = 'ผู้ดูแลโครงการ';
    break;
    case 'contractor':
      title.value = 'ผู้รับเหมา';
    break;
  }
})
</script>

<template>
  <li 
    class="job__people__item"
    :class="{
      'has-name': showName
    }"
  >
    <h5>{{ title }}</h5>
    <ul class="job__people__avatar">
      <li>
        <!-- <img :src=" avatar "> -->
        <Avatar :src=" avatar "></Avatar>
      </li>
      <li 
        v-if="showName"
        class="contact"
      >
        <span class="contact__name">{{ info.full_name }}</span>

        <div class="contact__icon">
          <li v-if="info.phone !== ''">
            <a :href="'tel:'+info.phone">
              <FontIcon slug="phone" font="la" />
            </a>
            {{ info.phone }}
          </li>
        </div>
      </li>
    </ul>
  </li>
</template>

<style scoped>
.avatar {
  width: 34px;
  height: 34px;
}
</style>