<script setup>
/**
 * Member register
 * 
 * @description 
 *   Member can login by 2 way
 *    1. System log in (Phone/Email + Pass)
 *    2. Social media register (components)
 */
import LoginWrap from '@/components/login/LoginWrap.vue';
import SocialLogin from '@/components/login/SocialLogin.vue';
import Input from '@/components/display/form/InputRound.vue';
import ButtonSet from '@/components/display/form/ButtonSetFullWidth.vue';
import FormMessage from '@/components/display/form/FormMessage.vue';

import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter  } from 'vue-router';
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, email, minLength, maxLength } from '@vuelidate/validators';
import { http } from '@/utilities/http';
import { loading } from '@/utilities/display';



const route = useRoute()
const router = useRouter()
const loaded = ref(false);
const pageTitle = ref('สมัครเป็นผู้ใช้งาน');
const errMsg = ref('');
const emailError = ref(false);
const phoneError = ref(false);
const state = reactive({
  data: {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    passwd: '',
    repasswd: '',
    role: 'customer',
  }
})

// initial validate
const v$ = useVuelidate({
  first_name: {required},
  last_name: {required},
  phone: {
    required, 
    numeric,
    minLength: minLength(10),
    maxLength: maxLength(10),
  },
  email: {email},
  passwd: {
    required,
    minLength: minLength(6),
  },
  repasswd: {
    required,
    minLength: minLength(6),
  },
}, state.data);


/** Life Cycle ------------------- */
onMounted(() => {
  if (route.name === 'RegisterContractorView' ) {
    pageTitle.value = 'สมัครเป็นผู้รับเหมา';
    state.data.role = 'contractor';
  }

  loaded.value = true;
  loading(false);
})


/** Method ------------------- */
const onSubmit = async () => {
  await v$.value.$touch();
  if (v$.value.$invalid) return;
  
  emailError.value = false;
  phoneError.value = false;

  loading();
  console.log(state.data);
  await http
        .post('/member', state.data)
        .then((res)=> {
          console.log(res.data);
          console.log(router);
          if(res.data.insert_id == undefined) {
            errMsg.value = 'การบันทึกข้อมูลไม่สมบูรณ์ กรุณาลองใหม่อีกครั้ง !!!';
          } else {
            router.push('/register/term-of-use/' + res.data.insert_id);
          }
        })
        .catch(err => {
          switch(err.response.data.status) {
            case 'email_repeat':
              errMsg.value = 'อีเมล์นี้มีผู้ใช้งานแล้ว !!!';
              emailError.value = true;
            break
            case 'phone_repeat':
              errMsg.value = 'หมายเลขนี้มีผู้ใช้งานแล้ว !!!';
              phoneError.value = true;
            break

            default:
              console.log(err);
              errMsg.value = 'การบันทึกข้อมูลไม่สมบูรณ์ กรุณาลองใหม่อีกครั้ง !!!';
            break
          }
          loading(false);
        });
  loading(false);
}
</script>

<template>
  <LoginWrap 
    v-if=" loaded "
    :title=" pageTitle " 
    pageClass="page__login"
  >
    <template v-slot:form>
      <div class="head">
        <h3>หากยังไม่เคยสร้างบัญชี</h3>
        <h4>โปรดสร้างบัญชีใหม่ด้านล่างนี้</h4>
      </div>

      <FormMessage 
        :text=" errMsg " 
        type="error"
      />

      <form 
        @submit.prevent="onSubmit"
        class="form"
      >
        <fieldset>
          <!-- v-model="state.data.user" -->
          <Input 
            inputType="radiolist"
            label="ต้องการสมัครเป็น" 
            v-model="state.data.role"
            :option="[
              {id: 'customer',    name: 'ลูกค้า'},
              {id: 'contractor',  name: 'ผู้รับเหมา'},
            ]"
          />

          <Input 
            label="ชื่อ" 
            placeholder="กรอกชื่อ..."
            v-model="state.data.first_name"
            :validate="v$.first_name"
            :required="true"
          />
          
          <Input 
            label="นามสกุล" 
            placeholder="กรอกนามสกุล..."
            v-model="state.data.last_name"
            :validate="v$.last_name"
            :required="true"
          />

          <Input 
            label="เบอร์มือถือ (ตัวเลข 10 หลักเท่านั้น)" 
            placeholder="กรอกเบอร์โทรศัพท์..."
            v-model="state.data.phone"
            :validate="v$.phone"
            :required="true"
            :class="{ error: phoneError }"
          />

          <Input 
            label="อิเมล์" 
            placeholder="กรอกอิเมล์..."
            v-model="state.data.email"
            :validate="v$.email"
            :class="{ error: emailError}"
          />
            
          <Input 
            label="รหัสผ่าน (อย่างน้อย 6 หลัก)" 
            placeholder="กรอกรหัสผ่าน..."
            inputType="password"
            v-model="state.data.passwd"
            :validate="v$.passwd"
            :required="true"
          />

          <Input 
            label="รหัสผ่านยืนยัน" 
            placeholder="กรอกรหัสผ่านยืนยัน..."
            inputType="password"
            v-model="state.data.repasswd"
            :validate="v$.repasswd"
            :required="true"
          />

          <ButtonSet text="สมัครสมาชิก" icon="user" />
          <p class="bottom__link">
            <router-link to="/login">เข้าสู่ระบบ</router-link>
          </p>

          <SocialLogin />
          <p class="remark txt--center">
            เมื่อลงชื่อเข้าใช้ถือว่ายอมรับ<a href="//beaverman.com/policy" target="_blank">
              ข้อกำหนดการใช้บริการ
            </a>ของทาง Beaverman แล้ว
          </p>
        </fieldset>
      </form>
    </template>
  </LoginWrap>
</template>