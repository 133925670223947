export const customerNav = [
  {
    title:  'โครงการของฉัน',
    path:   '/',
    icon:   'bag',
  },
  {
    title:  'การชำระเงิน',
    path:   '/bill',
    icon:   'dollar',
  },
  {
    title:  'ข้อมูลผู้ใช้งาน',
    path:   '/profile',
    icon:   'profile',
  },
  
];

export const contractorNav = [
  {
    title:  'ค้นหาโครงการ',
    path:   '/',
    icon:   'bag',
  },
  {
    title:  'โครงการลูกค้าของฉัน',
    path:   '/job/relate',
    icon:   'bag',
  },
  {
    title:  'ข้อมูลผู้ใช้งาน',
    path:   '/profile',
    icon:   'user',
  },
  {
    title:  'เงื่อนไขการรับงาน',
    path:   '/contractor/term',
    icon:   'file-alt',
  },
];



export const mainNav = [
  {
    title: 'บริการของเรา',
    slug: '/service'
  },
  {
    title: 'แนะนำผู้รับเหมา',
    slug: '/recommended'
  },
  {
    title: 'โฮมกูรู',
    slug: '/homeguru'
  },
  {
    title: 'ประเมินราคา',
    slug: '/estimate-cost'
  },
  {
    title: 'คู่มือ',
    slug: '/help'
  },
  {
    title: 'ติดต่อเรา',
    slug: '/contact'
  },
]