<script setup>
import { defineProps } from 'vue';
import currentUser from '@/services/member/currentUser';
import numeral from 'numeral';


// const props = 
defineProps({
  bill: Object,
  showProjectName: {
    type: Boolean,
    default: false
  },
})
const memberRole = currentUser('role');
</script>


<template>
  <div 
    class="data__box period__item"
  >
    <div class="inner">
      <div class="fcol col-title">
        <h4 class="title">
          <div v-if="showProjectName">
            {{ bill.project_name }}
          </div>
          {{ bill.title }}
        </h4>
        <p>{{ bill.detail }}</p>
      </div>

      <div class="fcol col-pay">
        <span class="label">ลูกค้า</span>
        <span 
          v-if="bill.paid_status == 9"
          class="statue-green"
        >
          <img src="@/assets/images/bill/bill-check.svg" />
          ชำระแล้ว
          <ul 
            v-if=" memberRole === 'customer' "
            class="bill-files"
          >
            <li
              v-for="file in bill.file_slip"
              :key="file"
            >
              <a :href="file.uri" target="_blank">
                {{file.name}}
              </a>
            </li>
          </ul>
        </span>
        <span 
          v-else-if="bill.paid_status == 5"
          class="statue-orange"
        >
          <img src="@/assets/images/bill/bill-wait.svg" />
          รอการอนุมัติ
          <div 
            v-if=" memberRole === 'customer' "
            class="mt--1"
          >
            <router-link 
              :to="'/bill/' + bill.bill_id" 
              class="btn btn--submit"
            >
              แก้ไขหลักฐานการชำระ
            </router-link>
          </div>
        </span>
        <span v-else>
          <img src="@/assets/images/bill/bill-none.svg" />
          รอการชำระเงิน

          <div 
            v-if=" memberRole === 'customer' "
            class="mt--1"
          >
            <router-link 
              :to="'/bill/' + bill.bill_id" 
              class="btn btn--submit"
            >
              แจ้งหลักฐานการชำระ
            </router-link>
          </div>
        </span>
      </div>
      
      <div class="fcol col-paid">
        <span class="label">ผู้รับเหมา</span>
        <span 
          v-if="bill.withdraw_status == 9"
          class="statue-green"
        >
          <img src="@/assets/images/bill/bill-check.svg" />
          จ่ายแล้ว


          <ul 
            v-if=" memberRole === 'contractor' "
            class="bill-files"
          >
            <li
              v-for="file in bill.file_reciept"
              :key="file"
            >
              <a :href="file.uri" target="_blank">
                {{file.name}}
              </a>
            </li>
          </ul>
        </span>
        <span 
          v-else-if="bill.withdraw_status == 5"
          class="statue-orange"
        >
          <img src="@/assets/images/bill/bill-wait.svg" />
          รอการอนุมัติ
        </span>
        <span v-else>
          <img src="@/assets/images/bill/bill-none.svg" />
          ยังไม่เบิก
        </span>
      </div>
      
      <div class="col-cost">
        <span class="label">มูลค่างาน</span>
        <strong>
          {{ numeral(bill.net_amount).format('0,0') }} บาท
        </strong>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn--submit {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 13px;
}
.col-title p {
  font-size: 13px;
  line-height: 20px;
  color: #666;
}
.col-pay img,
.col-paid img {
  display: inline-block;
  margin-bottom: -3px;
  width: 16px;
}
.statue-green {
  color: #30B481;
}
.statue-orange {
  /* color: #E55252; */
}

.bill-files {
  margin-top: 10px;
}
.bill-files li {
  font-size: 12px;
  line-height: 20px;
}
</style>