<script setup>
import BoqTr from './AddonBoqTr.vue';
// import SVGIcon from '@/components/display/icon/SVGIconComp.vue';
import FontIconComp from '@/components/display/icon/FontIconComp.vue';
import { ref, onMounted, computed, defineProps, defineEmits, watchEffect } from 'vue';
const numeral = require('numeral');

const emit = defineEmits([
  'decrease-indent',
  'increase-indent',
  'add-row',
  'remove-row',
]);
const props = defineProps({
  index: Number,
  parent: String,
  data: Object,
  unit: Object,
  editable: {
    type: Boolean,
    default: true
  }
});
const item = ref([]);
const childParent = ref('');
const hasChild = computed(() => {
  if(item.value.child=== undefined) {
    return true
  } else {
    return item.value.child.length > 0
  }
});


/** Life Cycle -----------------------*/
onMounted(() => {
  item.value = props.data;

  if (props.parent === '-1') {
    childParent.value = '';
  } else if(props.parent !== '') {
    childParent.value = props.parent + '.';
    item.value.parent = childParent.value;
  }
})

watchEffect(() => {
  if(item.value.child === undefined) return 0;

  if(item.value.child.length > 0) {
    item.value.total = 0;
    item.value.child.forEach((c_item)=> {
      item.value.total += Math.round(c_item.total * 100) / 100;
    })
    return;
  }
  // state.total = toFloat(state.total)

  let mat_price = parseFloat(item.value.mat_price);
  let man_cost = parseFloat(item.value.man_cost);
  let cost_flat = parseFloat(item.value.net_price);

  if(item.value.price_type == 1) {
    item.value.total = (isNaN(mat_price) || isNaN(man_cost)) 
                        ? 0
                        : (mat_price + man_cost) * item.value.value;
  } else {
    item.value.total = isNaN(cost_flat) ? 0 : cost_flat;
  }
  // item.value.total = parseFloat(toFloat(item.value.total));
  item.value.total = Math.round(item.value.total * 100) / 100;
});



/** Method -----------------------*/

/**
 * Decrease item
 */
// const decreaseItem = (currentItem, index = 1, parentIndex = -1, depth = -2) => {
//   try {
//     // if depth is current depth, pass data to parent depth
//     if(depth === -2) {
//       let currentItemParent = currentItem.parent.replace(/.$/,'')
//       let indexToRoot = currentItemParent.split('.');
//       let lastIndex = parseInt(indexToRoot[indexToRoot.length-1]);
//       console.log(lastIndex);
//       emit('decrease-indent', currentItem, index, lastIndex, -1);
//     } 
//     else if(depth === -1) {
//       // get item from same level that index more than currentItem index
//       // to currentIndex child
//       if(index < item.value.child.length -1) {
//         console.log('new child');
//         console.log(item.value.child.slice(index+1));

//         // set new current child
//         currentItem.child = [
//           ...currentItem.child,
//           ...item.value.child.slice(index+1)
//         ];

//         // remove item that moved to current child
//         item.value.child = item.value.child.slice(0, index); 
//       }


//       // if depth is parent depth, pass data to grand parent depth to process
//       emit('decrease-indent', currentItem, index, parentIndex, 0);

//       // remove child that move out
//       item.value.child.splice(index, 1);
//     } 
//     else {
//       // set new item parent slug
//       currentItem.parent = props.parent === '-1' ? '' : childParent.value + (index+1);

//       // insert to grand parent depth array by specific index
//       item.value.child.splice(parentIndex, 0, currentItem);
//     }
//   } catch(err) {
//     console.log('decrease error');
//     console.log(err);
//   }
// }


/**
 * increase item
 */
// const increaseItem = (currentItem, index) => {
//   let currentItemParent = currentItem.parent;
//   // console.log(currentItem);

//   try{
//     // if had parent code, remove last dot (.)
//     if(currentItemParent.length > 0) {
//       currentItemParent = currentItemParent.replace(/.$/,'')
//     }

//     // if it's not depth to process, emit to parent
//     if(props.parent === currentItemParent) {
//       emit('increase-indent', currentItem, index);
//     } else {
//       // process for indent list
//       const newItem = {...currentItem};
//       item.value.child.splice(index, 1); // remove old item
//       item.value.child[index-1].child.push(newItem); // push to new position
//     }
//   } catch(err) {
//     console.log('increase error');
//     console.log(err);
//   }
// }


/**
 * add item below
 */
const addItemBelow = (index, depth = -1) => {
  const childTemplate = {
    title: '',
    detail: '',
    value: '',
    unit: '',
    parent: '',
    price_type: 1,
    mat_price: 0,
    man_cost: 0,
    net_price: 0,
    total: 0,
    spec: '',
    child: [],
  };
  if(depth === -1) {
    // had child add item to first chld
    if(item.value.child.length > 0) {
      item.value.child.splice(0, 0, childTemplate);
    } else {
      emit('add-row', index, 0);
    }
  }
  else {
    item.value.child.splice(index+1, 0, childTemplate);
  }
}


/**
 * remove item by pass index emit to removeChild in parent method
 */
const removeItem = (index) => {
  emit('remove-row', index);
}

/**
 * remove child
 */
const removeChild = (index) => {
  item.value.child.splice(index, 1);
}


/**
 * Generate Number Indent class
 */
 const numberIndentClass = () => {
  if(item.value.parent === undefined) return '';
  
  return 'pl--' + (item.value.parent.split(".").length - 1 )
}
</script>

<template>
  <tr v-if=" index !== -1">
    <td>
      <div :class="numberIndentClass()">
        {{ childParent + (index+1) }}</div>
    </td>
    <td>
      <div v-if="editable">
        <input 
          type="text" 
          v-model="item.name" 
          placeholder="หัวข้อ..."
        />
        
        <div class="control">
          
          <!-- Decrease -->
          <!-- <a 
            @click.prevent="decreaseItem(item, index)"
            v-if="parent !== ''"
            href="#"
            title="Decrease indent"
            class="decrease--indent"
          >
            <FontIconComp font="la" size="lg" slug="caret-square-left" />
          </a> -->
  
          <!-- Increase -->
          <!-- <a 
            v-if="index > 0"
            @click.prevent="increaseItem(item, index)"
            href="#"
            title="Increase indent"
            class="inecrease--indent"
          >
            <FontIconComp font="la" size="lg" slug="caret-square-right" />
          </a> -->
  
          <!-- Add -->
          <a 
            @click.prevent="addItemBelow(index)"
            href="#"
            title="Add item below"
            class="add"
          >
            <FontIconComp font="la" size="lg" slug="plus-square" />
          </a>
  
          <!-- remove -->
          <a 
            @click.prevent="removeItem(index)"
            href="#"
            title="Remove current row"
            class="remove"
          >
            <FontIconComp font="la" size="lg" slug="trash" />
          </a>
        </div>
      </div>
      <div v-else>
        {{ item.name }}
      </div>
    </td>
    <td>
      <div v-if="!hasChild">
        <input 
          v-if="editable"
          type="number"
          step="0.01"
          required
          v-model="item.value"
          placeholder="ปริมาณ..."
          style="width: 40%; margin-right: 5%;"
        />
        <span v-else>
          {{ item.value }}
        </span>
        
        <select 
          v-if="editable"
          v-model="item.unit"
          required
          class="unit--selector"
          style="width: 55%"
        >
          <option value="">- -</option>
          <option 
            v-for="u in unit"
            :key="u"
            :value="u.name"
          >
            {{ u.name }}
          </option>
        </select>
        <span v-else>
          {{ item.unit }}
        </span>
      </div>
    </td>
    <td class="td-check">
      <input 
        v-if="!hasChild"
        type="radio"
        v-model="item.price_type"
        value="1"
        :disabled=" !editable "
      />
    </td>
    <td class="td-price">
      <input 
        v-if="!hasChild"
        type="number"
        v-model="item.mat_price"
        step="0.01"
        :required=" item.price_type==1 "
        :disabled=" !editable || item.price_type==9 "
      />
    </td>
    <td class="td-price">
      <input 
        v-if="!hasChild"
        type="number"
        v-model="item.man_cost"
        step="0.01"
        :required=" item.price_type==9 "
        :disabled=" !editable || item.price_type==9 "
      />
    </td>

    <td class="td-check">
      <input 
        v-if="!hasChild"
        type="radio"
        v-model="item.price_type"
        value="9"
        :disabled=" !editable "
      />
    </td>
    <td class="td-price">
      <input 
        v-if="!hasChild"
        type="number"
        v-model="item.net_price"
        step="0.01"
        :required=" item.price_type==9 "
        :disabled=" !editable || item.price_type==1 "
      />
    </td>
    <td class="col--right">
      <span v-if="!hasChild">
        {{ numeral(item.total).format('0,0.00')}}
      </span>
    </td>
    <td class="td-price">
      <input 
        v-if="!hasChild"
        type="text"
        v-model="item.spec"
        :disabled=" !editable "
      />
    </td>
  </tr>

  <BoqTr
    v-for="(child, childIndex) in item.child"
    :key="child"
    :data="child"
    :index="childIndex"
    :parent="parent === '-1' ? '' : childParent + (index+1)"
    :unit="unit"
    :editable="editable"
    @add-row="addItemBelow"
    @remove-row="removeChild"
  />
    <!-- @decrease-indent="decreaseItem"
    @increase-indent="increaseItem" -->
</template>


<style scoped>
.unit--selector {
  margin-top: 3px;
}
</style>