<script setup>
import SVGIcon from '@/components/display/icon/SVGIconComp.vue';
import { ref, onMounted, defineProps} from 'vue';
import { useRouter } from 'vue-router'; //useRoute, 
import { customerNav, contractorNav } from '@/vars/theme/navigator';
import logout from '@/services/member/logout';
import isCont from '@/services/member/isCont';

defineProps({
  mode: {
    type: String,
    default: 'sidebar'
  }
})
const router = useRouter();
const navigators = ref([]);

/** Life Cycle ------------ */
onMounted(() => {
  navigators.value = isCont() ? contractorNav : customerNav;
})


const logoutClick = async () => {
  await logout();
  router.push('/login');
}
</script>

<template>
  <nav class="bm__nav">
    <ul>
      <li 
        v-for="nav in navigators"
        :key="nav"
      >
        <!-- :class="{
          active: (route.name === 'Profile' && nav.path === '/profile') || (index === 0 && route.name !== 'Profile')
        }" -->
        <router-link 
          :to=" nav.path " 
          :title=" nav.title "
        >
          <SVGIcon 
            :icon=" nav.icon " 
            v-if="mode != 'header'"
          />
          {{nav.title}}
        </router-link>
      </li>
      <li>
        <a
          @click.prevent=" logoutClick "
          href="#"
        >
          <SVGIcon 
            icon="logout" 
            v-if="mode != 'header'"
          />
          ออกจากระบบ
        </a>
      </li>
    </ul>
  </nav>
</template>