/**
 * Get member authen from API
 *  if member status is not disable or removed
 *  this function will get new token for update in state
 */
import { VueCookieNext } from 'vue-cookie-next'

export default async function authenSetBackLink(path) {
  const jwtCookieName = process.env.VUE_APP_AUTH_MEMBER;
  await VueCookieNext.setCookie(jwtCookieName+'.back_link', path, '5min');
}