<script setup>
import { ref, onMounted, defineProps } from 'vue';


defineProps({
  data: Object
})
const loaded = ref(false);

onMounted(() => {
  loaded.value = true;
})
</script>

<template>
  <div v-if="loaded">
    <span 
      class="badge--item"
      v-for="badge in data" 
      :key="badge.badge_id"
    >
      <a href="#">
        <img 
          v-if="badge.cover != ''" 
          :src="badge.cover" 
        />
        <span class="badge--description">
          <strong>{{ badge.name }}</strong>
          {{ badge.description }}
        </span>
      </a>
    </span>
  </div>
</template>

<style scoped>
.badge--item {
  display: inline-block;
  margin-right: 3px;
}
.badge--item a {
  position: relative;
}
.badge--item img {
  height: 24px;
}
.badge--description {
  display: none;
  position: absolute;
  top: 26px;
  left: 0;
  padding: 10px 15px;
  border-radius: 12px;
  width: 200px;
  background: #fff;
  font-size: 12px;
  line-height: 18px;
  z-index: 50;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  color: #000;
}
.badge--description strong {
  display: block;
  font-size: 14px;
  line-height: 20px;
}
.badge--item a:hover .badge--description {
  display: block;
}
</style>