import { http } from '@/utilities/http';

export default async function getFileList(section, refer_id, slug = '', order = '') {
  let file = [];
  let params = {
    section: section,
    refer_id: refer_id,
  };
  if (slug !== '')  params.slug = slug;
  if (order !== '') params.order = order;
  // console.log(params);

  await http
        .get('/file', { params: params } )  
        .then((res) => {
          file = res.data.rows;
        })
        .catch((err) => {
          console.log('get file error')
          console.log(err)
        });
  
  return file;
}
