<script setup>
import Avatar from '@/components/display/image/AvatarComp.vue';
import Navigator from '@/components/display/NavigatorComp.vue';

import { ref, onMounted, watchEffect } from 'vue';
import { loading } from '@/utilities/display';
import { roleToName } from '@/services/member/memberFormat';
import currentUser from '@/services/member/currentUser';
import uploadFileReplace from '@/services/file/uploadFileReplace';
import { useStore } from 'vuex';

const store = useStore();
const loaded = ref(false);
const member = ref(currentUser('all'));
const linkProfile = '/profile';

onMounted(() => {
  // console.log(member.value);
  loaded.value = true;
})
watchEffect(() => {
  member.value = currentUser('all');
})


const newImage = ref(null);
const fileChangeHandle = () => {
  // detect file size is over than 5mb
  if (newImage.value.size >= 50000000) {
    alert('ขนาดไฟล์เกิน 50MB กรุณาลดขนาดไฟล์ก่อน upload')
    newImage.value = '';
    return '';
  }
  
  loading();

  // file input, section, member_id, slug
  uploadFileReplace(
      newImage.value.files[0], 
      'member', 
      member.value.member_id,
      'avatar',
    )
    .then((res) => {
      store.commit('updateAvatar', res.data.uri);
    });
  loading(false);
}
</script>

<template>
  <div class="sidebar">
    <div class="inner">
      <!-- 
      member avatar
      -->
      <!-- <Avatar /> -->
      <div class="sidebar__avatar">
        <router-link 
          :to="linkProfile" 
          class="sidebar__avatar__image"
        >
          <Avatar :src=" member.avatar " />
        </router-link>

        <a
          @click.prevent="$refs.newImage.click()"
          href="#" 
          class="sidebar__avatar__edit"
        >
          <img src="@/assets/images/icon/edit-avatar.svg">
        </a>
        <input 
          @change="fileChangeHandle()" 
          ref="newImage" 
          type="file" 
          accept="image/png, image/gif, image/jpeg"
          class="input"
        />
      </div>

      <!-- 
      member profile 
        - name
        - role
      -->
      <div class="sidebar__profile">
        <h4>
          <a 
            title="ดูโปรไฟล์ของคุณ" 
            href="#" 
            class="sidebar__profile__name"
          >
            {{ member.full_name }}
          </a>
        </h4>
        <div class="sidebar__profile__role">
          {{ roleToName(member.role) }}
        </div>
      </div>
      
      <!--
      navigator
      -->
      <Navigator class="sidebar__nav"></Navigator>
    </div>
  </div>
</template>

<style scoped>
.sidebar__avatar__edit {
  z-index: 500;
}
.input {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0.0;
}
</style>