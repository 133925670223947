/**
 * All route for post job
 *   CreaeDraftJob - pcreate job draft data 
 */

import JobPostView from '@/views/job/post/JobPostFormView.vue';


import EditTimelineView from '@/views/job/post/EditTimelineView.vue'

export const jobPostRoute = [
  // create draft job data
  {
    path: '/job/draft',
    name: 'CreateDraftJob',
    component: () => import('@/views/job/post/CreateDraftJob.vue')
  },

  // post job
  {
    path: '/job/post/:id',
    name: 'JobPostView',
    component: JobPostView,
    meta: { 
      title: 'โพสโครงการใหม่'
    }
  },

  // post job
  // {
  //   path: '/job/post/:id/',
  //   name: 'JobPostView',
  //   component: JobPostView,
  //   meta: { 
  //     title: 'โพสโครงการใหม่'
  //   }
  // },

  // edit post job
  // from draft job detail page or job view step 1
  {
    path: '/job/edit/:id',
    name: 'JobEditView',
    component: JobPostView,
    meta: { 
      title: 'แก้ไขรายละเอียดโครงการ'
    }
  },




  {
    path: '/job/:id/timeline/:progress_id/checkin',
    name: 'AddTimelineViewCheckin',
    component: EditTimelineView,
    meta: { title: 'เช็คอิน' }
  },
  {
    path: '/job/:id/timeline/:progress_id/checkout',
    name: 'AddTimelineViewCheckout',
    component: EditTimelineView,
    meta: { title: 'เช็คเอ๊าท์' }
  },
  {
    path: '/job/:id/timeline/:progress_id/note',
    name: 'AddTimelineViewNote',
    component: EditTimelineView,
    meta: { title: 'บันทึก' }
  },
  {
    path: '/job/:id/timeline/:progress_id/update-progress',
    name: 'AddTimelineViewUpdate',
    component: EditTimelineView,
    meta: { title: 'อัพเดท' }
  },
  {
    path: '/job/:id/timeline/:progress_id/appointment',
    name: 'AddTimelineViewAppointment',
    component: EditTimelineView,
    meta: { title: 'ส่งงาน' }
  },
  {
    path: '/job/:id/timeline/:progress_id/customer-feedback',
    name: 'AddTimelineViewCustomerFeedback',
    component: EditTimelineView,
    meta: { title: 'ความคิดเห็นลูกค้า' }
  },



  {
    path: '/job/:id/agreement/:agreement_id',
    name: 'ViewAgreement',
    component: () => import('@/views/job/agreement/JobAgreement.vue'),
    meta: { title: 'ข้อมูลสัญญา' }
  },
  
  {
    path: '/job/:id/proposal/:contractor_id',
    name: 'ViewProposal',
    component: () => import('@/views/job/JobProposal.vue'),
    meta: { title: 'ข้อมูลใบเสนอราคา' }
  },
  
  // {
  //   path: '/job/:id/timeline/:progress_id/customer-feedback',
  //   name: 'AddTimelineViewCustomerFeedback',
  //   component: EditTimelineView,
  //   meta: { title: 'ความคิดเห็นลูกค้า' }
  // },
  // {
  //   path: '/job/:id/timeline/edit/:progress_id',
  //   name: 'EditTimelineView',
  //   component: EditTimelineView,
  //   meta: { title: 'แก้ไขข้อมูลอัพเดทความคืบหน้า' }
  // },
];