<script setup>
import { defineProps } from 'vue'
const moment = require('moment');



const props = defineProps({
  code: {
    type: String,
    default: ''
  },
  status: {
    type: Number,
    default: 9
  },
  data: {
    type: Object,
    default: () => {}
  },
})


const calRemainInterestDate = () => {
  if(props.publishDate === '') {
    return -1
  }

  var a = moment();
  var b = moment(props.data.publish_date);
  const diff = props.data.interest_date_limit - a.diff(b, 'days')   // =1
  return diff;
}
</script>


<template>
  <div class="job__code">
    <span 
      class="code"
      :class="{
        closed: data.status == 5
      }"
    >
      {{ code }} 
    </span>
    <div class="interest__status">
      <span v-if="data.close_interest == 9" class="interest__close">
        ปิดการรับสมัครผู้สนใจ
      </span>
      <span v-else-if="calRemainInterestDate() <= 0" class="interest__close">
        สิ้นสุดการรับสมัครผู้สนใจ
      </span>
      <span v-else-if="data.close_interest == 0">
        เหลือเวลา {{ calRemainInterestDate() }} วัน
      </span>
    </div>
  </div>
</template>

<style scoped>
.interest__close {
  color: #E55252;
}
.interest__close {
  color: #E55252;
}
</style>