import { http } from '@/utilities/http';

export default async function uploadFileReplace(file, section, refer_id, slug, eventHandled = {}) {
  let formData = new FormData();
  formData.append('file',     file );
  formData.append("refer_id", refer_id );
  formData.append("section",  section );
  formData.append("slug",     slug );
  formData.append("replace",  1);

  return await http.post(
    '/file/upload', 
    formData, 
    eventHandled
  );
}