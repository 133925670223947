/**
 * All route for list page
 *  - Find all job
 *  - My job
 *  - My relate job
 */

export const jobListRoute = [
  {
    path: '/job/find',
    name: 'JobFind',
    component: () => import('@/views/job/list/JobListView.vue'),
    meta: {
      title: 'ค้นหาโครงการ'
    }
  },
  {
    path: '/job/me',
    name: 'MyJob',
    component: () => import('@/views/job/list/MyJobView.vue'),
    meta: {
      title: 'โครงการของฉัน'
    }
  },
  {
    path: '/job/relate',
    name: 'MyRelateJob',
    component: () => import('@/views/job/list/JobRelateView.vue'),
    meta: {
      title: 'โครงการลูกค้าของฉัน'
    }
  },

  {
    path: '/bill',
    name: 'AllJobBill',
    component: () => import('@/views/bill/BillView.vue'),
    meta: {
      title: 'การชำระเงิน'
    }
  },
  {
    path: '/bill/job/:id',
    name: 'AllJobBillView',
    component: () => import('@/views/bill/BillJobView.vue'),
    meta: {
      title: 'การชำระเงิน'
    }
  },
  {
    path: '/bill/:bill_id',
    name: 'BillFormView',
    component: () => import('@/views/bill/BillFormView.vue'),
    meta: {
      title: 'แจ้งหลักฐานการชำระเงิน'
    }
  },
];