<script setup="props, {emit}">
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: [String,Number],
    default: 0,
  }
});

const onToggle = () => {
  emit('update:modelValue', props.modelValue == 1 ? 0 : 1);
}
</script>

<template>
  <span 
    class="input__toggle"
  >
    <span>tog</span>
    <a 
      @click.prevent = "onToggle"
      href="#" 
      class="input__toggle__wrap"
      :class="{
        active: modelValue == 1
      }"
    >
    </a>
  </span>
</template>


<style>
.input__toggle,
.input__toggle__wrap {
  display: inline-block;
}
.input__toggle {
  content: ' ';
  position: relative;
  /* margin: 0 10px; */
  width: 44px;
}
.input__toggle span {
  opacity: 0.0;
}
.input__toggle__wrap {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
  width: 44px;
  height: 25px;
  background: #d7d7d7;
}
.input__toggle__wrap::after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 20px;
  width: 19px;
  height: 19px;
  background: #fff;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}
.input__toggle__wrap.active {
  background: #30B481;
}
.input__toggle__wrap.active:after {
  left: 21px;
}
</style>