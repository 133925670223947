/**
 * Ger current member profile by stored token
 */
import store from '@/store';

export default function currentUser( scope = 'member_id' ) {
  let output = '';
  let data = store.getters.currentUser;

  if (scope === 'all') {
    output = data;
  } else if (scope === 'role') {
    output = data[scope].length == 0 ? 'customer' : data[scope][0];
  } else {
    output = data[scope];
  }

  return output;
}